/* eslint sort-keys: 0 */
import {Action, ActionType, PayloadAction} from 'typesafe-actions';

import {
	ADD_DEVICE_PARAMETER_VALUES,
	ADD_DEVICE_PARAMETER_VALUES_ERROR,
	ADD_DEVICE_PARAMETER_VALUES_SUCCESS,
	DELETE_DEVICE_PARAMETER_VALUES,
	DELETE_DEVICE_PARAMETER_VALUES_ERROR,
	DELETE_DEVICE_PARAMETER_VALUES_SUCCESS,
	EXECUTE_DEVICE_COMMAND,
	EXECUTE_DEVICE_COMMAND_ERROR,
	EXECUTE_DEVICE_COMMAND_SUCCESS,
	FIND_DEVICE,
	FIND_DEVICE_CLEAR,
	FIND_DEVICE_ERROR,
	FIND_DEVICE_SUCCESS,
	GET_DEVICE_PARAMETER_VALUES,
	GET_DEVICE_PARAMETER_VALUES_ERROR,
	GET_DEVICE_PARAMETER_VALUES_SUCCESS,
	INIT_SPEED_TEST,
	INIT_SPEED_TEST_CLEAR,
	INIT_SPEED_TEST_ERROR,
	INIT_SPEED_TEST_SUCCESS,
	LOAD_DEVICES,
	LOAD_DEVICES_ERROR,
	LOAD_DEVICES_SUCCESS,
	LOAD_DEVICE_INFO,
	LOAD_DEVICE_INFO_ERROR,
	LOAD_DEVICE_INFO_SUCCESS,
	LOAD_DEVICE_JOBS,
	LOAD_DEVICE_JOBS_CLEAR,
	LOAD_DEVICE_JOBS_ERROR,
	LOAD_DEVICE_JOBS_SUCCESS,
	LOAD_DEVICE_NOTIFICATIONS,
	LOAD_DEVICE_NOTIFICATIONS_CLEAR,
	LOAD_DEVICE_NOTIFICATIONS_ERROR,
	LOAD_DEVICE_NOTIFICATIONS_SUCCESS,
	SET_DEVICE_PARAMETER_VALUES,
	SET_DEVICE_PARAMETER_VALUES_ERROR,
	SET_DEVICE_PARAMETER_VALUES_SUCCESS,
	TRIGGER_DEVICE_FIRMWARE_UPDATE,
	TRIGGER_DEVICE_FIRMWARE_UPDATE_ERROR,
	TRIGGER_DEVICE_FIRMWARE_UPDATE_SUCCESS,
	TRIGGER_SPEED_TEST,
	TRIGGER_SPEED_TEST_ERROR,
	TRIGGER_SPEED_TEST_STATUS_UPDATED_SUCCESS,
	TRIGGER_SPEED_TEST_SUCCESS
} from '../../constants';
import ZenObservable from 'zen-observable-ts';
import { FormikFormSubmitPromise, PaginatedResults, createAction } from '@indigo-cloud/common-react';
import { DeleteParameterValuesResponse, Device, DeviceCommand, DeviceGroupActivationWindow, DeviceParameterValues, DeviceResponse, DeviceTriggerFirmwareUpdateResponse, DevicesResponse, ExchangeTokensResponse, Job, SpeedTestStatusResponse, SpeedTestType } from '../../../shared';
import { SetParameterValuesInputModalFormValues } from '../../../shared/components/SetParameterValues/components';
import { DeleteParameterValuesInputModalFormValues } from '../../../shared/components/DeleteParameterValues/components';
import { AddParameterValuesInputModalFormValues } from '../../../shared/components/AddParameterValues/components';

export const devicesActions = {
	/**
	 * ADD_DEVICE_PARAMETER_VALUES
	 */
	addDeviceParameterValues: (agentEndpointId: string, addParameterValues: AddParameterValuesInputModalFormValues, formikPromise: FormikFormSubmitPromise): PayloadAction<typeof ADD_DEVICE_PARAMETER_VALUES, { addParameterValues: typeof addParameterValues; agentEndpointId: typeof agentEndpointId, formikPromise: typeof formikPromise  }> => createAction(ADD_DEVICE_PARAMETER_VALUES, { addParameterValues, agentEndpointId, formikPromise   }),
	 addDeviceParameterValuesError: <ErrorType extends Error>(error: ErrorType, agentEndpointId: string): PayloadAction<typeof ADD_DEVICE_PARAMETER_VALUES_ERROR, { agentEndpointId: typeof agentEndpointId; error: ErrorType, }> =>
		createAction(ADD_DEVICE_PARAMETER_VALUES_ERROR, {agentEndpointId, error}),
	addDeviceParameterValuesSuccess:  (
		response: DeviceParameterValues,
		agentEndpointId: string
	): PayloadAction<typeof ADD_DEVICE_PARAMETER_VALUES_SUCCESS, { agentEndpointId: typeof agentEndpointId, response: typeof response }> =>
		createAction(ADD_DEVICE_PARAMETER_VALUES_SUCCESS, { agentEndpointId, response }),
	/**
	 * DELETE_DEVICE_PARAMETER_VALUES
	 */
	deleteDeviceParameterValues: (agentEndpointId: string, deleteParameterValues: DeleteParameterValuesInputModalFormValues, formikPromise: FormikFormSubmitPromise): PayloadAction<typeof DELETE_DEVICE_PARAMETER_VALUES, { agentEndpointId: typeof agentEndpointId, deleteParameterValues: typeof deleteParameterValues; formikPromise: typeof formikPromise;    }> => createAction(DELETE_DEVICE_PARAMETER_VALUES, { agentEndpointId, deleteParameterValues, formikPromise   }),
	 deleteDeviceParameterValuesError: <ErrorType extends Error>(error: ErrorType, agentEndpointId: string): PayloadAction<typeof DELETE_DEVICE_PARAMETER_VALUES_ERROR, { agentEndpointId: typeof agentEndpointId; error: ErrorType, }> =>
		createAction(DELETE_DEVICE_PARAMETER_VALUES_ERROR, {agentEndpointId, error}),
	deleteDeviceParameterValuesSuccess:  (
		response: DeleteParameterValuesResponse,
		agentEndpointId: string
	): PayloadAction<typeof DELETE_DEVICE_PARAMETER_VALUES_SUCCESS, { agentEndpointId: typeof agentEndpointId, response: typeof response }> =>
		createAction(DELETE_DEVICE_PARAMETER_VALUES_SUCCESS, { agentEndpointId, response }),
	/**
	 * EXECUTE_DEVICE_COMMAND
	 */
	 executeDeviceCommand: (agentEndpointId: string, command: DeviceCommand, formikPromise: FormikFormSubmitPromise): PayloadAction<typeof EXECUTE_DEVICE_COMMAND, { agentEndpointId: typeof agentEndpointId, command: typeof command; formikPromise: typeof formikPromise,   }> => createAction(EXECUTE_DEVICE_COMMAND, { agentEndpointId, command, formikPromise   }),
	 executeDeviceCommandError: <ErrorType extends Error>(error: ErrorType, agentEndpointId: string): PayloadAction<typeof EXECUTE_DEVICE_COMMAND_ERROR, { agentEndpointId: typeof agentEndpointId; error: ErrorType, }> =>
		 createAction(EXECUTE_DEVICE_COMMAND_ERROR, {agentEndpointId, error}),
		 executeDeviceCommandSuccess:  (
		 response: DeviceParameterValues,
		 agentEndpointId: string
	 ): PayloadAction<typeof EXECUTE_DEVICE_COMMAND_SUCCESS, { agentEndpointId: typeof agentEndpointId, response: typeof response }> =>
		createAction(EXECUTE_DEVICE_COMMAND_SUCCESS, { agentEndpointId, response }),

	findDevice: (searchString: string): PayloadAction<typeof FIND_DEVICE, { searchString: typeof searchString }> => createAction(FIND_DEVICE, { searchString }),
	findDeviceSuccess:  (
		response: DeviceResponse
	): PayloadAction<typeof FIND_DEVICE_SUCCESS, DeviceResponse> =>
		createAction(FIND_DEVICE_SUCCESS, response),
	findDeviceError: <ErrorType extends Error>(error: ErrorType, searchString: string): PayloadAction<typeof FIND_DEVICE_ERROR, {error: ErrorType, searchString: typeof searchString}> =>
		createAction(FIND_DEVICE_ERROR, { error, searchString }),
	findDeviceClear: (): PayloadAction<typeof FIND_DEVICE_CLEAR, any> => createAction(FIND_DEVICE_CLEAR, {}),

	/**
	 * GET_DEVICE_PARAMETER_VALUES
	 */
	 getDeviceParameterValues: (agentEndpointId: string, parameterPaths: string[], formikPromise: FormikFormSubmitPromise): PayloadAction<typeof GET_DEVICE_PARAMETER_VALUES, { agentEndpointId: typeof agentEndpointId, formikPromise: typeof formikPromise, paramPaths: typeof parameterPaths;  }> => createAction(GET_DEVICE_PARAMETER_VALUES, { agentEndpointId, formikPromise, paramPaths: parameterPaths  }),
	 getDeviceParameterValuesError: <ErrorType extends Error>(error: ErrorType, agentEndpointId: string): PayloadAction<typeof GET_DEVICE_PARAMETER_VALUES_ERROR, { agentEndpointId: typeof agentEndpointId; error: ErrorType, }> =>
		 createAction(GET_DEVICE_PARAMETER_VALUES_ERROR, {agentEndpointId, error}),
	 getDeviceParameterValuesSuccess:  (
		 response: DeviceParameterValues,
		 agentEndpointId: string
	 ): PayloadAction<typeof GET_DEVICE_PARAMETER_VALUES_SUCCESS, { agentEndpointId: typeof agentEndpointId, response: typeof response }> =>
		createAction(GET_DEVICE_PARAMETER_VALUES_SUCCESS, { agentEndpointId, response }),
	/**
	 * INIT_SPEED_TEST
	 */
	initSpeedTest: (agentEndpointId: string, line: string): PayloadAction<typeof INIT_SPEED_TEST, { agentEndpointId: typeof agentEndpointId, line: typeof line; }> => createAction(INIT_SPEED_TEST, { agentEndpointId, line: line }),
	initSpeedTestClear: (agentEndpointId: string): PayloadAction<typeof INIT_SPEED_TEST_CLEAR, { agentEndpointId: typeof agentEndpointId; }> =>
		createAction(INIT_SPEED_TEST_CLEAR, {agentEndpointId}),
	initSpeedTestError: <ErrorType extends Error>(error: ErrorType, agentEndpointId: string): PayloadAction<typeof INIT_SPEED_TEST_ERROR, { agentEndpointId: typeof agentEndpointId; error: ErrorType, }> =>
	 createAction(INIT_SPEED_TEST_ERROR, { agentEndpointId, error }),

	initSpeedTestSuccess:  (
	  agentEndpointId: string,
	  tokens: ExchangeTokensResponse
	): PayloadAction<typeof INIT_SPEED_TEST_SUCCESS, { agentEndpointId: typeof agentEndpointId; tokens: typeof tokens }> =>
	 createAction(INIT_SPEED_TEST_SUCCESS, { agentEndpointId, tokens}),
	/**
	 * LOAD_DEVICE_INFO
	 */
	loadDeviceInfo: (searchString: string): PayloadAction<typeof LOAD_DEVICE_INFO, { searchString: typeof searchString }> => createAction(LOAD_DEVICE_INFO, {
		searchString
	}),



	loadDeviceInfoError: <ErrorType extends Error>(searchString: string, error: ErrorType): PayloadAction<typeof LOAD_DEVICE_INFO_ERROR, {searchString: typeof searchString, error: ErrorType}> =>
		 createAction(LOAD_DEVICE_INFO_ERROR, {searchString: typeof searchString, error}),


	loadDeviceInfoSuccess: (
		searchString: string,
		 response: Device
	): PayloadAction<typeof LOAD_DEVICE_INFO_SUCCESS, { searchString: string, response: Device }> =>
		createAction(LOAD_DEVICE_INFO_SUCCESS, { searchString, response }),


	/**
	 * LIST JOBS
	 */
	loadDeviceJobs: ({limit, cursor, pageIndex, filters, agentEndpointId}: {filters?: Record<string, unknown>, limit?: number, cursor?: string, pageIndex?: number, agentEndpointId: string}): PayloadAction<typeof LOAD_DEVICE_JOBS, {filters: typeof filters, limit?: number, agentEndpointId: typeof agentEndpointId, cursor?: string, pageIndex?: number}> => createAction(LOAD_DEVICE_JOBS, { cursor, filters, limit, agentEndpointId, pageIndex}),
	loadDeviceJobsClear: (): PayloadAction<typeof LOAD_DEVICE_JOBS_CLEAR, any> => createAction(LOAD_DEVICE_JOBS_CLEAR, {}),
	loadDeviceJobsError: (error: Error): PayloadAction<typeof LOAD_DEVICE_JOBS_ERROR, { error: typeof error }> =>
		createAction(LOAD_DEVICE_JOBS_ERROR, { error }),
	loadDeviceJobsSuccess: (agentEndpointId: string, result: PaginatedResults<Job>, pageIndex: number, filters?: Record<string, unknown>): PayloadAction<typeof LOAD_DEVICE_JOBS_SUCCESS, { agentEndpointId: typeof agentEndpointId; pageIndex: number; result: typeof result , filters: typeof filters}> =>
		createAction(LOAD_DEVICE_JOBS_SUCCESS, { agentEndpointId, filters, pageIndex, result }),

	/** Notifications  */
	loadDeviceNotifications: ({agentEndpointId, nextToken, pageIndex, filters}: {filters?: Record<string, unknown>, nextToken?: string, pageIndex?: number, agentEndpointId: string}): PayloadAction<typeof LOAD_DEVICE_NOTIFICATIONS, {filters: typeof filters,  pageIndex?: number, nextToken?: string, agentEndpointId: typeof agentEndpointId }> => createAction(LOAD_DEVICE_NOTIFICATIONS, {
		agentEndpointId,
		pageIndex,
		filters,
		nextToken
	 }),
	loadDeviceNotificationsClear: (): PayloadAction<typeof LOAD_DEVICE_NOTIFICATIONS_CLEAR, any> => createAction(LOAD_DEVICE_NOTIFICATIONS_CLEAR, {}),
	loadDeviceNotificationsError: <ErrorType extends Error>(error: ErrorType, agentEndpointId: string): PayloadAction<typeof LOAD_DEVICE_NOTIFICATIONS_ERROR, {error: ErrorType, agentEndpointId: typeof agentEndpointId}> =>
		createAction(LOAD_DEVICE_NOTIFICATIONS_ERROR, { error, agentEndpointId }),
	loadDeviceNotificationsSuccess: (
		agentEndpointId: string,
		response: PaginatedResults<Notification>,
		pageIndex: number,
		filters?: Record<string, unknown>
	): PayloadAction<typeof LOAD_DEVICE_NOTIFICATIONS_SUCCESS, { agentEndpointId: typeof agentEndpointId, pageIndex: number, response: typeof response, filters: typeof filters}> =>
		createAction(LOAD_DEVICE_NOTIFICATIONS_SUCCESS, { agentEndpointId, response, filters, pageIndex}),

	/**
	 * LOAD_DEVICES
	 */
	loadDevices: (): Action<typeof LOAD_DEVICES> => createAction(LOAD_DEVICES),
	loadDevicesError: <ErrorType extends Error>(error: ErrorType): PayloadAction<typeof LOAD_DEVICES_ERROR, {error: ErrorType}> =>
		createAction(LOAD_DEVICES_ERROR, {error}),
	loadDevicesSuccess:  (
		response: DevicesResponse
	): PayloadAction<typeof LOAD_DEVICES_SUCCESS, DevicesResponse> =>
		createAction(LOAD_DEVICES_SUCCESS, response),

	/**
	 * SET_DEVICE_PARAMETER_VALUES
	 */
	setDeviceParameterValues: (agentEndpointId: string, setParameterValues: SetParameterValuesInputModalFormValues, formikPromise: FormikFormSubmitPromise): PayloadAction<typeof SET_DEVICE_PARAMETER_VALUES, { agentEndpointId: typeof agentEndpointId, formikPromise: typeof formikPromise, setParameterValues: typeof setParameterValues;  }> => createAction(SET_DEVICE_PARAMETER_VALUES, { agentEndpointId, formikPromise, setParameterValues  }),
	 setDeviceParameterValuesError: <ErrorType extends Error>(error: ErrorType, agentEndpointId: string): PayloadAction<typeof SET_DEVICE_PARAMETER_VALUES_ERROR, { agentEndpointId: typeof agentEndpointId; error: ErrorType, }> =>
		createAction(SET_DEVICE_PARAMETER_VALUES_ERROR, {agentEndpointId, error}),
	setDeviceParameterValuesSuccess:  (
		response: DeviceParameterValues,
		agentEndpointId: string
	): PayloadAction<typeof SET_DEVICE_PARAMETER_VALUES_SUCCESS, { agentEndpointId: typeof agentEndpointId, response: typeof response }> =>
		createAction(SET_DEVICE_PARAMETER_VALUES_SUCCESS, { agentEndpointId, response }),

	/**
	 * DEVICE_FIRMWARE_UPDATE
	 * TODO: Change actions to accept an object instead of list of parameters
	 */
	 triggerDeviceFirmwareUpdate: (agentEndpointId: string, firmwareName: string, shouldForceDowngrade: boolean, activationWindows: (DeviceGroupActivationWindow | Record<string, unknown>)[], formikPromise: FormikFormSubmitPromise): PayloadAction<typeof TRIGGER_DEVICE_FIRMWARE_UPDATE, { agentEndpointId: typeof agentEndpointId, activationWindows: typeof activationWindows, firmwareName: typeof firmwareName, formikPromise: typeof formikPromise,  shouldForceDowngrade: typeof shouldForceDowngrade  }> => createAction(TRIGGER_DEVICE_FIRMWARE_UPDATE, { agentEndpointId, activationWindows, firmwareName, formikPromise, shouldForceDowngrade  }),
	 triggerDeviceFirmwareUpdateError: <ErrorType extends Error>(error: ErrorType, agentEndpointId: string): PayloadAction<typeof TRIGGER_DEVICE_FIRMWARE_UPDATE_ERROR, { agentEndpointId: typeof agentEndpointId; error: ErrorType, }> =>
		 createAction(TRIGGER_DEVICE_FIRMWARE_UPDATE_ERROR, {agentEndpointId, error}),
	triggerDeviceFirmwareUpdateSuccess:  (
		 response: DeviceTriggerFirmwareUpdateResponse,
		 agentEndpointId: string
	 ): PayloadAction<typeof TRIGGER_DEVICE_FIRMWARE_UPDATE_SUCCESS, { agentEndpointId: typeof agentEndpointId, response: typeof response }> =>
		createAction(TRIGGER_DEVICE_FIRMWARE_UPDATE_SUCCESS, { agentEndpointId, response }),

	 /**
	 * TRIGGER_SPEED_TEST
	 */
	triggerSpeedTest: (agentEndpointId: string, type: SpeedTestType, line: string, formikPromise: FormikFormSubmitPromise): PayloadAction<typeof TRIGGER_SPEED_TEST, { agentEndpointId: typeof agentEndpointId, formikPromise: typeof formikPromise, line: typeof line; type: typeof type;  }> => createAction(TRIGGER_SPEED_TEST, { agentEndpointId, formikPromise, line: line, type: type  }),
	triggerSpeedTestError: <ErrorType extends Error>(error: ErrorType, agentEndpointId: string): PayloadAction<typeof TRIGGER_SPEED_TEST_ERROR, { agentEndpointId: typeof agentEndpointId; error: ErrorType, }> =>
		createAction(TRIGGER_SPEED_TEST_ERROR, { agentEndpointId, error }),
	triggerSpeedTestStatusUpdatedSuccess:  (
		agentEndpointId: string,
		response: SpeedTestStatusResponse
	): PayloadAction<typeof TRIGGER_SPEED_TEST_STATUS_UPDATED_SUCCESS, { agentEndpointId: typeof agentEndpointId, response: typeof response }> =>
		createAction(TRIGGER_SPEED_TEST_STATUS_UPDATED_SUCCESS, { agentEndpointId, response }),
	triggerSpeedTestSuccess:  (
		agentEndpointId: string,
		subscription: ZenObservable.Subscription
	): PayloadAction<typeof TRIGGER_SPEED_TEST_SUCCESS, { agentEndpointId: typeof agentEndpointId, subscription: typeof subscription }> =>
		createAction(TRIGGER_SPEED_TEST_SUCCESS, { agentEndpointId, subscription })

};

/**
 * @see {@link https://github.com/piotrwitek/typesafe-actions#user-content-redux-actions-1}
 */
// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-redeclare
export type devicesActions = ActionType<typeof devicesActions>;
