import { request } from '@indigo-cloud/common-react';
import { call, put, takeEvery } from '@redux-saga/core/effects';
import moment from 'moment';
import { awsExports } from '../../shared';
import { JobDetailsActions } from '../actions';
import { LOAD_JOB_DETAILS, LOAD_JOB_EXECUTION_DETAIL_FOR_DEVICE } from '../constants';


const basePath = '/v1/jobs';
const basePathForJobExecution = '/v1/agents'
function* loadJobDetails(action: ReturnType<typeof JobDetailsActions.loadJobDetails>) {
	const { payload: { jobId } } = action;
	try {
		const requestUrl = `${basePath}/${jobId}`
		const response = yield call(
			request,
			{
				apiName: 'usp',
				awsExports,
				options: {
					queryStringParameters: {}
				},
				path: requestUrl
			}
		);
		// Convert dates using moment before putting them in the store
		const jobDetails = {
			...response.properties,
			startedAt: moment(response.properties.startedAt, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY HH:mm:ssA'),
			updatedAt: moment(response.properties.updatedAt, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY HH:mm:ssA')
		};
		yield put(JobDetailsActions.loadJobDetailsSuccess(jobId, jobDetails));
	} catch (error) {
		console.error('An error occurred while loading the job details.', error);
		yield put(JobDetailsActions.loadJobDetailsError(error));
	}
}

function* loadJobExecutionDetail(action: ReturnType<typeof JobDetailsActions.loadDeviceJobExecutionDetails>) {
	const { payload: { jobId, agentEndPoint, executionNumber } } = action;
	try {
		const requestUrl = `${basePathForJobExecution}/${agentEndPoint}/jobs/${jobId}`
		const response = yield call(
			request,
			{
				apiName: 'usp',
				awsExports,
				options: {
					queryStringParameters: {
						executionNumber: executionNumber
					}
				},
				path: requestUrl
			}
		);
		const jobExecution= {
			...response.properties,
			lastUpdatedAt: moment(response.properties.lastUpdatedAt, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY HH:mm:ssA'),
			queuedAt: moment(response.properties.queuedAt, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY HH:mm:ssA'),
			startedAt: moment(response.properties.startedAt, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY HH:mm:ssA')
		};
		yield put(JobDetailsActions.loadDeviceJobExecutionDetailsSuccess(jobId, jobExecution));
	} catch (error) {
		console.error('An error occurred while loading the job execution details.', error);
		yield put(JobDetailsActions.loadDeviceJobExecutionDetailsError(error));
	}
}



export const jobSagas = () => {

	function* watcher() {
		yield takeEvery(LOAD_JOB_DETAILS, loadJobDetails);
		yield takeEvery(LOAD_JOB_EXECUTION_DETAIL_FOR_DEVICE, loadJobExecutionDetail);
	}

	return {
		loadJobDetails,
		watcher
	};
};
