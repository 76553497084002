import {useEffect, useRef} from 'react';

export const useToken = (): string | undefined => {
	const tokenReference = useRef<string | undefined>();

	useEffect(() => {
		const federatedCredentials = localStorage.getItem('federatedCredentials');

		if (federatedCredentials) {
			const parsedCredentials = JSON.parse(federatedCredentials);
			const extractedToken = parsedCredentials.token;
			tokenReference.current = extractedToken;
		}
	}, []);

	return tokenReference.current;
};
