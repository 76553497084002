import {createSelector} from 'reselect';
import { DeviceGroup, DeviceGroupAddDevicesResponse, DeviceGroupList, DeviceGroupRemoveDevicesResponse, DeviceGroupTriggerFirmwareUpdateResponse, ThingList } from '../../shared/types';

import {RootState} from '../reducers';
import {createOperationResultSelector, operationSelector} from '@indigo-cloud/common-react';
/** 
* CREATE Device Group
*/
const createDeviceGroupSelector = (state: RootState) => {
	return state?.deviceGroup?.createDeviceGroupOperation;
};

const createDeviceGroupOperationSelector = createSelector(createDeviceGroupSelector, (state) => operationSelector(state));
const createDeviceGroupOperationResultSelector = createOperationResultSelector(createDeviceGroupOperationSelector);

const getDeviceGroupsSelector = (state: RootState) => {
	return state?.deviceGroup.getDeviceGroupOperation;
};

const getDeviceGroupsOperationSelector = createSelector(getDeviceGroupsSelector, (state) => operationSelector<DeviceGroupList, Error>(state));
const getDeviceGroupsOperationResultsSelector = createOperationResultSelector(getDeviceGroupsOperationSelector);

const getDeviceGroupsDataSelector = createSelector(getDeviceGroupsOperationResultsSelector, (state) => state?.data);

const triggerDeviceGroupFirmwareUpdateSelector = (state: RootState) => {
	return state?.deviceGroup.triggerDeviceGroupFirmwareUpdateOperation;
};

const triggerDeviceGroupDevicesAddSelector = (state: RootState) => {
	return state?.deviceGroup.triggerDeviceGroupAddDevicesOperation;
};

const triggerDeviceGroupDevicesRemoveSelector = (state: RootState) => {
	return state?.deviceGroup.triggerDeviceGroupRemoveDevicesOperation;
};

const triggerDeviceGroupFirmwareUpdateOperationSelector = (deviceGroup: string) => {
	return createSelector(triggerDeviceGroupFirmwareUpdateSelector, (state) => {
		return operationSelector<DeviceGroupTriggerFirmwareUpdateResponse, Error>(state[deviceGroup]);
	})
};
const triggerDeviceGroupFirmwareUpdateOperationResultsSelector = (deviceGroup: string) => createOperationResultSelector(triggerDeviceGroupFirmwareUpdateOperationSelector(deviceGroup));

const triggerDeviceGroupDevicesAddOperationSelector = (deviceGroup: string) => {
	return createSelector(triggerDeviceGroupDevicesAddSelector, (state) => {
		return operationSelector<DeviceGroupAddDevicesResponse, Error>(state[deviceGroup]);
	})
};

const triggerDeviceGroupDevicesRemoveOperationSelector = (deviceGroup: string) => {
	return createSelector(triggerDeviceGroupDevicesRemoveSelector, (state) => {
		return operationSelector<DeviceGroupRemoveDevicesResponse, Error>(state[deviceGroup]);
	})
};

const getDeviceGroupInfoSelector = (state: RootState) => {
	return state?.deviceGroup.getDeviceGroupInfoOperation;
};

const getDeviceGroupInfoOperationSelector = (id: string) => {
	return createSelector(getDeviceGroupInfoSelector, (state) => {
		return operationSelector<DeviceGroup, Error>(state[id]);
	})
};

const deleteDeviceGroupSelector = (state: RootState) => {
	return state?.deviceGroup.deleteDeviceGroupOperation;
};

const deleteDeviceGroupOperationSelector = (id: string) => {
	return createSelector(deleteDeviceGroupSelector, (state) => {
		return operationSelector<boolean, Error>(state[id]);
	})
};

const deleteDeviceGroupOperationResultsSelector = (id: string) => createOperationResultSelector(deleteDeviceGroupOperationSelector(id));

const getDeviceGroupInfoOperationResultsSelector = (id: string) => createOperationResultSelector(getDeviceGroupInfoOperationSelector(id));

const getDevicesSelector = (state: RootState) => {
	return state?.deviceGroup.getDeviceGroupDevicesOperation;
};

const listDeviceGroupDevicesOperationSelector = (id: string) => {
	return createSelector(getDevicesSelector, (state) => {
		return operationSelector<ThingList, Error>(state[id]);
	})
};

const listDeviceGroupDevicesOperationPageSelector = (deviceGroup: string, pageNo: number, filters?: Record<string, unknown>) => createSelector(getDevicesSelector, (state) => {

	return operationSelector<ThingList, Error>(state?.[deviceGroup])?.result?.[`${pageNo}_${JSON.stringify(filters)}`]
});

const listDeviceGroupDevicesOperationAllSelector = (deviceGroup: string) => createSelector(getDevicesSelector, (state) => {

	const pages = state?.[deviceGroup]?.current;
	return Object.keys(pages || {}).reduce((previous, currentPageKey) => {
		const currentPageResults = pages?.[currentPageKey]?.data;
		return [
			...previous,
			...currentPageResults
		];
	}, [])

	return [];
});

export const deviceGroupsSelectors = {
	createDeviceGroupOperation: createDeviceGroupOperationSelector,
	createDeviceGroupOperationResult: createDeviceGroupOperationResultSelector,

	getDeviceGroupInfoOperation: getDeviceGroupInfoOperationSelector,
	getDeviceGroupInfoOperationResults: getDeviceGroupInfoOperationResultsSelector,

	deleteDeviceGroupOperation: deleteDeviceGroupOperationSelector,
	deleteDeviceGroupOperationResults: deleteDeviceGroupOperationResultsSelector,

	getDeviceGroupsData: getDeviceGroupsDataSelector,
	getDeviceGroupsOperation: getDeviceGroupsOperationSelector,

	listDeviceGroupDevicesOperation: listDeviceGroupDevicesOperationSelector,
	listDeviceGroupDevicesOperationPage: listDeviceGroupDevicesOperationPageSelector,
	listDeviceGroupDevicesOperationAll: listDeviceGroupDevicesOperationAllSelector,

	triggerDeviceGroupDevicesAddOperation: triggerDeviceGroupDevicesAddOperationSelector,
	triggerDeviceGroupDevicesRemoveOperation: triggerDeviceGroupDevicesRemoveOperationSelector,

	triggerDeviceGroupFirmwareUpdateOperation: triggerDeviceGroupFirmwareUpdateOperationSelector,
	triggerDeviceGroupFirmwareUpdateOperationResults: triggerDeviceGroupFirmwareUpdateOperationResultsSelector
};
