/**
 * LIST_REPORTS
 */
export const LIST_REPORTS = 'REPORT.LIST_REPORTS';
export const LIST_REPORTS_SUCCESS = 'REPORT.LIST_REPORTS_SUCCESS';
export const LIST_REPORTS_ERROR = 'REPORT.LIST_REPORTS_ERROR';

/**
 * LOAD_REPORT_INFO
 */
export const LOAD_REPORT_INFO = 'REPORT.LOAD_REPORT_INFO';
export const LOAD_REPORT_INFO_SUCCESS = 'REPORT.LOAD_REPORT_INFO_SUCCESS';
export const LOAD_REPORT_INFO_ERROR = 'REPORT.LOAD_REPORT_INFO_ERROR';

/**
 * CREATE_REPORT
 */
export const CREATE_REPORT = 'REPORT.CREATE_REPORT';
export const CREATE_REPORT_SUCCESS = 'REPORT.CREATE_REPORT_SUCCESS';
export const CREATE_REPORT_ERROR = 'REPORT.CREATE_REPORT_ERROR';

/**
 * UPDATE_REPORT
 */
export const UPDATE_REPORT = 'REPORT.UPDATE_REPORT';
export const UPDATE_REPORT_SUCCESS = 'REPORT.UPDATE_REPORT_SUCCESS';
export const UPDATE_REPORT_ERROR = 'REPORT.UPDATE_REPORT_ERROR';

/**
 * DELETE_REPORT
 */
export const DELETE_REPORT = 'REPORT.DELETE_REPORT';
export const DELETE_REPORT_SUCCESS = 'REPORT.DELETE_REPORT_SUCCESS';
export const DELETE_REPORT_ERROR = 'REPORT.DELETE_REPORT_ERROR';
