
import { Action, ActionType, PayloadAction } from 'typesafe-actions';

import { CREATE_REPORT, CREATE_REPORT_ERROR, CREATE_REPORT_SUCCESS } from '../../constants';
import {FormikFormSubmitPromise, createAction} from '@indigo-cloud/common-react';
import { Report, ReportsResponse } from '../../../shared';
import { DELETE_REPORT, DELETE_REPORT_ERROR, DELETE_REPORT_SUCCESS, LIST_REPORTS, LIST_REPORTS_ERROR, LIST_REPORTS_SUCCESS, LOAD_REPORT_INFO, LOAD_REPORT_INFO_ERROR, LOAD_REPORT_INFO_SUCCESS, UPDATE_REPORT, UPDATE_REPORT_ERROR, UPDATE_REPORT_SUCCESS } from '../../constants';


export const reportActions = {




	/**
	 * CREATE_REPORT
	 */
	createReport: (model: Report, formikPromise: FormikFormSubmitPromise, createAnother?: boolean): PayloadAction<typeof CREATE_REPORT, { createAnother: typeof createAnother; formikPromise: typeof formikPromise;  model: typeof model;  }> => createAction(CREATE_REPORT, { createAnother, formikPromise, model }),
	createReportError: (error: Error): PayloadAction<typeof CREATE_REPORT_ERROR, { error: typeof error }> =>
		createAction(CREATE_REPORT_ERROR, { error }),
	createReportSuccess: (response: any): PayloadAction<typeof CREATE_REPORT_SUCCESS, { response: typeof response }> =>
		createAction(CREATE_REPORT_SUCCESS, { response }),



	 /**
	 * DELETE_REPORT
	 */
	deleteReport: (model: Report): PayloadAction<typeof DELETE_REPORT, { model: typeof model }> => createAction(DELETE_REPORT, { model }),
	deleteReportError: (error: Error): PayloadAction<typeof DELETE_REPORT_ERROR, { error: typeof error }> =>
		createAction(DELETE_REPORT_ERROR, { error }),
	deleteReportSuccess: (response: any): PayloadAction<typeof DELETE_REPORT_SUCCESS, { response: typeof response }> =>
		createAction(DELETE_REPORT_SUCCESS, { response }),

	/**
	 * LIST_REPORTS
	 */
	listReports: (): Action<typeof LIST_REPORTS> => createAction(LIST_REPORTS),
	listReportsError: <ErrorType extends Error>(error: ErrorType): PayloadAction<typeof LIST_REPORTS_ERROR, {error: ErrorType}> =>
		createAction(LIST_REPORTS_ERROR, {error}),
	listReportsSuccess:  (
		response: ReportsResponse
	): PayloadAction<typeof LIST_REPORTS_SUCCESS, ReportsResponse> =>
		createAction(LIST_REPORTS_SUCCESS, response),
	/**
	 * LOAD_REPORT_INFO
	 */
	loadReportInfo: (reportId: string): PayloadAction<typeof LOAD_REPORT_INFO, { reportId: typeof reportId }> => createAction(LOAD_REPORT_INFO, {
		reportId
	}),
	loadReportInfoError: <ErrorType extends Error>(reportId: string, error: ErrorType): PayloadAction<typeof LOAD_REPORT_INFO_ERROR, {reportId: typeof reportId, error: ErrorType}> =>
		 createAction(LOAD_REPORT_INFO_ERROR, { error, reportId: typeof reportId}),
	loadReportInfoSuccess: (
		reportId: string,
		response: Report
	): PayloadAction<typeof LOAD_REPORT_INFO_SUCCESS, { reportId: string, response: Report }> =>
		createAction(LOAD_REPORT_INFO_SUCCESS, { reportId, response }),

	/**
	 * UPDATE_REPORT
	 */
	updateReport: (model: Report, formikPromise: FormikFormSubmitPromise, createAnother?: boolean): PayloadAction<typeof UPDATE_REPORT, { createAnother: typeof createAnother; formikPromise: typeof formikPromise;  model: typeof model;  }> => createAction(UPDATE_REPORT, { createAnother, formikPromise, model }),
	updateReportError: (error: Error): PayloadAction<typeof UPDATE_REPORT_ERROR, { error: typeof error }> =>
		createAction(UPDATE_REPORT_ERROR, { error }),
	updateReportSuccess: (response: any): PayloadAction<typeof UPDATE_REPORT_SUCCESS, { response: typeof response }> =>
		createAction(UPDATE_REPORT_SUCCESS, { response })
};

/**
 * @see {@link https://github.com/piotrwitek/typesafe-actions#user-content-redux-actions-1}
 */
// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-redeclare
export type reportActions = ActionType<typeof reportActions>;
