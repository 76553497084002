import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import styles from './Details.component.module.scss';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';

export interface DetailsProperties {
	object: Record<string, any>;
	title?: string;
	type?: string;
	isLoading?: boolean;
	stripEmptyFields?: boolean;
}

export const detailsDataSelectors = {
	progress: 'Details-progress'
};

export const Details: React.FC<DetailsProperties> = (properties) => {
	const {
		object: {
			tableData,
			...rest
		} = {},
		title,
		type,
		isLoading,
		stripEmptyFields,
		children
	} = properties;

	return (
		<List className={styles.container}>
			{title && <ListSubheader>
				{title}
			</ListSubheader>}

			{isLoading ? <div className={styles.containerLoader}><CircularProgress data-cy={detailsDataSelectors.progress} color='primary' size={'2rem'} /></div> : Object.keys(rest).filter((key) => {
				if (stripEmptyFields) {
					return rest[key as keyof typeof rest]?.length;
				}
				return true;
			}).map((key) => {
				const keyFormatted = !Number.isNaN(+key) ? `${type ? `${type} ` : ''} ${Number.parseInt(key, 10) + 1} ` : key;
				return typeof rest[key as keyof typeof rest] === 'object' ? <Details key={key} object={rest[key]} title={keyFormatted} /> : (<><ListItem key={key}>
					<ListItemText
						primary={_.startCase(key)}
						secondary={rest[key as keyof typeof rest]}
						className={styles.listItemText} />
				</ListItem><Divider component="li" /></>);
			})}
		</List>
	);
};

Details.propTypes = {
	/* eslint-disable-next-line react-redux/no-unused-prop-types */
	object: PropTypes.any
};


