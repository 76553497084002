import { ActionType, PayloadAction } from 'typesafe-actions';
import { LocationChangeAction } from 'connected-react-router';
import { authActions } from './Auth';
import { menuActions } from './Menu';
import { reportActions } from './Report';
export const rootActions = {
	...authActions,
	...menuActions,
	...reportActions
};

export const rootActionsAll = {
	auth: authActions,
	menu: menuActions,
	report: reportActions
};

export type FilterRootActionsByPayloadActions<Action extends rootActions> = Action extends PayloadAction<any, any>
	? Action
	: never;

export type RootPayloadActions = FilterRootActionsByPayloadActions<rootActions>;

export type rootActions = ActionType<typeof rootActions> | LocationChangeAction;
