import {combineReducers} from 'redux';
import {StateType} from 'typesafe-actions';
import { DeepReadonlyObject, OperationState, PaginatedResults, getLoadingStatusInProgress, getStateError, getStateInProgress, getStateInitial, getStateSuccess } from '@indigo-cloud/common-react';
import { Device, DeviceCommand, DeviceParameterValues, DeviceResponse, DeviceTriggerFirmwareUpdateResponse, DevicesResponse, ExchangeTokensResponse, SpeedTestStatusResponse } from '../../../shared';
import { ADD_DEVICE_PARAMETER_VALUES, ADD_DEVICE_PARAMETER_VALUES_ERROR, ADD_DEVICE_PARAMETER_VALUES_SUCCESS, DELETE_DEVICE_PARAMETER_VALUES, DELETE_DEVICE_PARAMETER_VALUES_ERROR, DELETE_DEVICE_PARAMETER_VALUES_SUCCESS, EXECUTE_DEVICE_COMMAND, EXECUTE_DEVICE_COMMAND_ERROR, EXECUTE_DEVICE_COMMAND_SUCCESS, FIND_DEVICE, FIND_DEVICE_CLEAR, FIND_DEVICE_ERROR, FIND_DEVICE_SUCCESS, GET_DEVICE_PARAMETER_VALUES, GET_DEVICE_PARAMETER_VALUES_ERROR, GET_DEVICE_PARAMETER_VALUES_SUCCESS, INIT_SPEED_TEST, INIT_SPEED_TEST_CLEAR, INIT_SPEED_TEST_ERROR, INIT_SPEED_TEST_SUCCESS, LOAD_DEVICES, LOAD_DEVICES_ERROR, LOAD_DEVICES_SUCCESS, LOAD_DEVICE_INFO, LOAD_DEVICE_INFO_ERROR, LOAD_DEVICE_INFO_SUCCESS, LOAD_DEVICE_NOTIFICATIONS, LOAD_DEVICE_NOTIFICATIONS_ERROR, LOAD_DEVICE_NOTIFICATIONS_SUCCESS, SET_DEVICE_PARAMETER_VALUES, SET_DEVICE_PARAMETER_VALUES_ERROR, SET_DEVICE_PARAMETER_VALUES_SUCCESS, TRIGGER_DEVICE_FIRMWARE_UPDATE, TRIGGER_DEVICE_FIRMWARE_UPDATE_ERROR, TRIGGER_DEVICE_FIRMWARE_UPDATE_SUCCESS, TRIGGER_SPEED_TEST, TRIGGER_SPEED_TEST_ERROR, TRIGGER_SPEED_TEST_STATUS_UPDATED_SUCCESS, TRIGGER_SPEED_TEST_SUCCESS  } from '../../constants';
import { devicesActions } from '../../actions';
import { Notification } from '../../../shared';



export type DevicesState = DeepReadonlyObject<{
	addDeviceParameterValuesOperation: {
		[agentEndpointId: string]: OperationState<DeviceParameterValues> & { request?: ReturnType<typeof devicesActions.addDeviceParameterValues>['payload'] } | undefined;
	};
	deleteDeviceParameterValuesOperation: {
		[agentEndpointId: string]: OperationState<DeviceParameterValues> & { request?: ReturnType<typeof devicesActions.deleteDeviceParameterValues>['payload'] } | undefined;
	};
	findDeviceOperation: OperationState<DeviceResponse>;
	getDevicesOperation: OperationState<DevicesResponse>;
	initSpeedTestOperation: {
		[agentEndpointId: string]: OperationState<ExchangeTokensResponse>;
	};
	triggerSpeedTestOperation: {
		[agentEndpointId: string]: OperationState<SpeedTestStatusResponse>;
	};
	getDeviceInfoOperation: {
		[agentEndpointId: string]: OperationState<Device>;
	};
	getDeviceParameterValuesOperation: {
		[agentEndpointId: string]: OperationState<DeviceParameterValues> & { request?: ReturnType<typeof devicesActions.getDeviceParameterValues>['payload'] } | undefined;
	};
	getNotificationsOperation: {
		[agentEndpointId: string]: OperationState<{ [pageNumber: number]: PaginatedResults<Notification>}>;
	}
	setDeviceParameterValuesOperation: {
		[agentEndpointId: string]: OperationState<DeviceParameterValues> & { request?: ReturnType<typeof devicesActions.setDeviceParameterValues>['payload'] } | undefined;
	};
	executeDeviceCommandOperation: {
		[agentEndpointId: string]: OperationState<DeviceCommand[]> & { request?: ReturnType<typeof devicesActions.executeDeviceCommand>['payload'] } | undefined;
	};
	triggerDeviceFirmwareUpdateOperation: {
		[agentEndpointId: string]: OperationState<DeviceTriggerFirmwareUpdateResponse>;
	};
}>;
export const initialDevicesState: DevicesState = {

	addDeviceParameterValuesOperation: {},
	deleteDeviceParameterValuesOperation: {},
	executeDeviceCommandOperation: {},
	findDeviceOperation: getStateInitial(),
	getDeviceInfoOperation: {},
	getDeviceParameterValuesOperation: {},
	getDevicesOperation: getStateInitial(),
	getNotificationsOperation: {},
	initSpeedTestOperation: {},
	setDeviceParameterValuesOperation: {},
	triggerDeviceFirmwareUpdateOperation: {},
	triggerSpeedTestOperation: {}
};

export const devicesReducers = combineReducers<DevicesState, any>({
	addDeviceParameterValuesOperation: (state = initialDevicesState.addDeviceParameterValuesOperation, action) => {

		switch (action.type) {
		case ADD_DEVICE_PARAMETER_VALUES: {
			const { agentEndpointId } = action.payload;
			return {
				[agentEndpointId]: getStateInProgress(action.payload)
			};
		}
		case ADD_DEVICE_PARAMETER_VALUES_SUCCESS: {
			const { agentEndpointId, response } = action.payload;
			return {
				...state,
				[agentEndpointId]: {
					...state[agentEndpointId],
					...getStateSuccess(response, state[agentEndpointId])
				}
			};
		}
		case ADD_DEVICE_PARAMETER_VALUES_ERROR: {
			const { error, agentEndpointId } = action.payload;

			console.log('error', error);

			return {
				...state,
				[agentEndpointId]: {
					...state[agentEndpointId],
					...getStateError(error)
				}
			};
		}
		default:
			return state;
		}
	},
	deleteDeviceParameterValuesOperation: (state = initialDevicesState.deleteDeviceParameterValuesOperation, action) => {

		switch (action.type) {
		case DELETE_DEVICE_PARAMETER_VALUES: {
			const { agentEndpointId } = action.payload;
			return {
				[agentEndpointId]: getStateInProgress(action.payload)
			};
		}
		case DELETE_DEVICE_PARAMETER_VALUES_SUCCESS: {
			const { agentEndpointId, response } = action.payload;
			return {
				...state,
				[agentEndpointId]: {
					...state[agentEndpointId],
					...getStateSuccess(response, state[agentEndpointId])
				}
			};
		}
		case DELETE_DEVICE_PARAMETER_VALUES_ERROR: {
			const { error, agentEndpointId } = action.payload;

			console.log('error', error);

			return {
				...state,
				[agentEndpointId]: {
					...state[agentEndpointId],
					...getStateError(error)
				}
			};
		}
		default:
			return state;
		}
	},
	executeDeviceCommandOperation: (state = initialDevicesState.executeDeviceCommandOperation, action) => {

		switch (action.type) {
		case EXECUTE_DEVICE_COMMAND: {
			const { agentEndpointId } = action.payload;
			return {
				[agentEndpointId]: getStateInProgress(action.payload)
			};
		}
		case EXECUTE_DEVICE_COMMAND_SUCCESS: {
			const { agentEndpointId, response } = action.payload;
			return {
				...state,
				[agentEndpointId]: getStateSuccess(response, state[agentEndpointId])
			};
		}
		case EXECUTE_DEVICE_COMMAND_ERROR: {
			const { error, agentEndpointId } = action.payload;

			console.log('error', error);

			return {
				...state,
				[agentEndpointId]: getStateError(error, state[agentEndpointId])
			};
		}
		default:
			return state;
		}
	},

	findDeviceOperation: (state = initialDevicesState.findDeviceOperation, action) => {
		switch (action.type) {
		case FIND_DEVICE: {
			return getStateInProgress(action.payload);
		}
		case FIND_DEVICE_SUCCESS: {
			const response = action.payload;

			return getStateSuccess(response);
		}
		case FIND_DEVICE_ERROR: {
			const { error } = action.payload;

			return getStateError(error, state);
		}
		case FIND_DEVICE_CLEAR: {
			return getStateInitial();
		}
		default:
			return state;
		}
	},

	getDeviceInfoOperation: (state = initialDevicesState.getDeviceInfoOperation, action) => {
		switch (action.type) {
		case LOAD_DEVICE_INFO: {
			const { searchString } = action.payload;
			return {
				[searchString]: getStateInProgress(action.payload)
			};
		}
		case LOAD_DEVICE_INFO_SUCCESS: {
			const { searchString, response } = action.payload;
			return {
				[searchString]: getStateSuccess(response)
			};
		}
		case LOAD_DEVICE_INFO_ERROR: {
			const { error, searchString } = action.payload;

			return {
				[searchString]: getStateError(error)
			};
		}
		default:
			return state;
		}
	},
	getDeviceParameterValuesOperation: (state = initialDevicesState.getDeviceParameterValuesOperation, action) => {
		switch (action.type) {
		case GET_DEVICE_PARAMETER_VALUES: {
			const { agentEndpointId } = action.payload;
			return {
				[agentEndpointId]: getStateInProgress(action.payload)
			};
		}
		case GET_DEVICE_PARAMETER_VALUES_SUCCESS: {
			const { agentEndpointId, response } = action.payload;
			return {
				...state,
				[agentEndpointId]: {
					...state[agentEndpointId],
					...getStateSuccess(response, state[agentEndpointId])
				}
			};
		}
		case GET_DEVICE_PARAMETER_VALUES_ERROR: {
			const { error, agentEndpointId } = action.payload;

			return {
				...state,
				[agentEndpointId]: {
					...state[agentEndpointId],
					...getStateError(error)
				}
			};
		}
		default:
			return state;
		}
	},
	getDevicesOperation: (state = initialDevicesState.getDevicesOperation, action) => {
		switch (action.type) {
		case LOAD_DEVICES: {
			return getStateInProgress(action.payload);
		}
		case LOAD_DEVICES_SUCCESS: {
			const response = action.payload;

			return getStateSuccess(response);
		}
		case LOAD_DEVICES_ERROR: {
			const { error } = action.payload;

			return getStateError(error);
		}
		default:
			return state;
		}
	},

	getNotificationsOperation: (state = initialDevicesState.getNotificationsOperation, action) => {
		switch (action.type) {
		case LOAD_DEVICE_NOTIFICATIONS: {
			const { agentEndpointId } = action.payload;
			return {
				[agentEndpointId]: {
					...state[agentEndpointId],
					...getLoadingStatusInProgress(),
					status: getLoadingStatusInProgress()
				}
			};
		}
		case LOAD_DEVICE_NOTIFICATIONS_SUCCESS: {
			const { agentEndpointId, response, filters, pageIndex } = action.payload;
			return {
			  [agentEndpointId]: getStateSuccess({
					...state[agentEndpointId].current,
					[`${pageIndex}_${JSON.stringify(filters)}`]: response
			  }, state[agentEndpointId])
			};
		}
		case LOAD_DEVICE_NOTIFICATIONS_ERROR: {
			const { error, agentEndpointId } = action.payload;

			return {
				[agentEndpointId]: getStateError(error)
			};
		}
		default:
			return state;
		}
	},

	initSpeedTestOperation: (state = initialDevicesState.initSpeedTestOperation, action) => {
		switch (action.type) {
		case INIT_SPEED_TEST: {
			const { agentEndpointId } = action.payload;
			return {
				[agentEndpointId]: getStateInProgress(action.payload)
			};
		}
		case INIT_SPEED_TEST_SUCCESS: {
			const { agentEndpointId, tokens } = action.payload;
			return {
				[agentEndpointId]: getStateSuccess(tokens, state[agentEndpointId])
			};
		}
		case INIT_SPEED_TEST_CLEAR: {
			const { agentEndpointId } = action.payload;
			return {
				[agentEndpointId]: getStateInitial()
			};
		}
		case INIT_SPEED_TEST_ERROR: {
			const { error, agentEndpointId } = action.payload;

			return {
				[agentEndpointId]: getStateError(error, state[agentEndpointId])
			};
		}
		default:
			return state;
		}
	},
	setDeviceParameterValuesOperation: (state = initialDevicesState.setDeviceParameterValuesOperation, action) => {

		switch (action.type) {
		case SET_DEVICE_PARAMETER_VALUES: {
			const { agentEndpointId } = action.payload;
			return {
				[agentEndpointId]: getStateInProgress(action.payload)
			};
		}
		case SET_DEVICE_PARAMETER_VALUES_SUCCESS: {
			const { agentEndpointId, response } = action.payload;
			return {
				...state,
				[agentEndpointId]: {
					...state[agentEndpointId],
					...getStateSuccess(response)
				}
			};
		}
		case SET_DEVICE_PARAMETER_VALUES_ERROR: {
			const { error, agentEndpointId } = action.payload;

			console.log('error', error);

			return {
				...state,
				[agentEndpointId]: {
					...state[agentEndpointId],
					...getStateError(error)
				}
			};
		}
		default:
			return state;
		}
	},

	triggerDeviceFirmwareUpdateOperation:  (state = initialDevicesState.triggerDeviceFirmwareUpdateOperation, action) => {
		switch (action.type) {
		case TRIGGER_DEVICE_FIRMWARE_UPDATE: {
			const { agentEndpointId } = action.payload;
			return {
				[agentEndpointId]: getStateInProgress(action.payload)
			};
		}
		case TRIGGER_DEVICE_FIRMWARE_UPDATE_SUCCESS: {
			const { agentEndpointId, response } = action.payload;
			return {
				[agentEndpointId]: getStateSuccess(response, state[agentEndpointId])
			};

		}
		case TRIGGER_DEVICE_FIRMWARE_UPDATE_ERROR: {
			const { error, agentEndpointId } = action.payload;

			return {
				[agentEndpointId]: getStateError(error, state[agentEndpointId])

			};
		}
		default:
			return state;
		}
	},
	triggerSpeedTestOperation: (state = initialDevicesState.triggerSpeedTestOperation, action) => {
		switch (action.type) {
		case TRIGGER_SPEED_TEST: {
			const { agentEndpointId } = action.payload;
			return {
				[agentEndpointId]: getStateInProgress(action.payload)
			};
		}
		case TRIGGER_SPEED_TEST_SUCCESS: {
			const { agentEndpointId, subscription } = action.payload;
			return {
				[agentEndpointId]: getStateSuccess({
					subscription
				}, state[agentEndpointId])


			};
		}
		case TRIGGER_SPEED_TEST_STATUS_UPDATED_SUCCESS: {
			const { agentEndpointId, response } = action.payload;
			return {
				[agentEndpointId]: getStateSuccess({
					...state[agentEndpointId]?.current as Pick<SpeedTestStatusResponse, 'subscription'>,
					status: response
				}, state[agentEndpointId])
			};
		}
		case TRIGGER_SPEED_TEST_ERROR: {
			const { error, agentEndpointId } = action.payload;

			return {
				[agentEndpointId]: getStateError(error, state[agentEndpointId])
			};
		}
		default:
			return state;
		}
	}

});

// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-redeclare
export type devicesReducers = StateType<typeof devicesReducers>;
