
import { Modal } from '@indigo-cloud/common-react';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { deviceGroupsSelectors } from '../../../store/selectors';
import { AddDeviceGroupInputModalForm, AddDeviceGroupInputModalFormReference } from './components/AddDeviceGroupInputModalForm';

export const addInputModalDataSelectors = {
	addInputModalForm: 'addInputModal-form',
	addInputModalModal: 'addsInputModal-modal'
};

export const AddDeviceGroup = forwardRef((properties, reference) => {
	useImperativeHandle(reference, () => ({
		openModal() {
			setShow(true);
		}
	}));

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);

	const referenceAddDeviceGroupModalInputForm = useRef<AddDeviceGroupInputModalFormReference>(null);

	const [isFormValid, setIsFormValid] = useState(false);
	const [isFormTouched, setIsFormTouched] = useState(false);
	const [isFormSubmitting, setIsFormSubmitting] = useState(false);

	const onModalOk = () => {
		referenceAddDeviceGroupModalInputForm.current?.formik?.submitForm();
	}

	const resetForm = () => {
		referenceAddDeviceGroupModalInputForm.current?.formik?.resetForm?.();
	}

	const onModalClear = () => {
		resetForm();
	}

	const onModalClose = () => {
		resetForm();
		handleClose();
	}

	const addDeviceGroupOperation = useSelector(deviceGroupsSelectors.createDeviceGroupOperation);

	return <Modal
		isOpen={show}
		isLoading={addDeviceGroupOperation?.isLoading}
		title={'Create Device Group'}
		onClickOk={onModalOk}
		onClickClose={onModalClose}
		// data-cy={releaseDeletePageDataSelectors.releaseDeleteModal}
		hide={{ scroll: true }}
		buttonOkProps={{
			disabled: (isFormSubmitting || isFormValid === false)
		}}
	>
		<AddDeviceGroupInputModalForm ref={referenceAddDeviceGroupModalInputForm} onFormIsTouchedChanged={setIsFormTouched} onFormIsSubmittingChanged={setIsFormSubmitting} onFormIsValidChanged={setIsFormValid} data-cy={addInputModalDataSelectors.addInputModalForm} onFormReset={onModalClose} />
	</Modal>
});

AddDeviceGroup.displayName = 'AddDeviceGroup';