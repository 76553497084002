import {CognitoUser} from 'amazon-cognito-identity-js';
import {combineReducers} from 'redux';
import {StateType} from 'typesafe-actions';
import * as AWS from 'aws-sdk';
import { DeepReadonlyObject, OperationState, getStateError, getStateInProgress ,getStateInitial, getStateSuccess } from '@indigo-cloud/common-react';

import {authActions} from '../../actions';
import {
	LOAD_AUTH_USER,
	LOAD_AUTH_USER_CLEAR,
	LOAD_AUTH_USER_ERROR,
	LOAD_AUTH_USER_SUCCESS,
	LOGIN,
	LOGIN_ERROR,
	LOGIN_SUCCESS,
	LOGOUT
} from '../../constants';
export type AuthState = DeepReadonlyObject<{
  login: OperationState<CognitoUser, AWS.AWSError>;
  user: OperationState<CognitoUser>;
}>;
export const initialAuthState: AuthState = {
	login: getStateInitial<CognitoUser, AWS.AWSError>(),
	user: getStateInitial()
};

export const handleAwsAuthError = (error: AWS.AWSError, meta?: any) => {
	// TODO: [04/12/20]: [DF]: Properly strongly-type AWSError with 'code' field.
	const {code} = (error as unknown) as {code: string};
	let friendlyMessage = '';

	switch (code) {
	case 'NotAuthorizedException': {
		// The error happens when the incorrect password is provided
		friendlyMessage = 'A matching user was found, but an incorrect password was provided.';
		break;
	}
	case 'UserNotFoundException': {
		friendlyMessage = 'No matching user was found with this email.';
		break;
	}
	case 'UserNotConfirmedException': {
		friendlyMessage = 'User\'s account is not yet confirmed.';
		break;
	}
	default: {
		friendlyMessage = 'An error occurred, please try again later.';
	}
	}

	return {
		friendlyMessage,
		...error,
		...meta
	};
};

export const authReducers = combineReducers<AuthState, authActions>({
	login: (state = initialAuthState.login, action) => {
		switch (action.type) {
		case LOGIN: {
			return getStateInProgress<CognitoUser, AWS.AWSError>();
		}
		case LOGIN_SUCCESS: {
			const {user} = action.payload;
			return getStateSuccess<CognitoUser, AWS.AWSError>(user);
		}
		case LOGIN_ERROR: {
			const {error, email} = action.payload;
			return getStateError<CognitoUser, AWS.AWSError>(handleAwsAuthError(error as AWS.AWSError, {email}));
		}
		default:
			return state;
		}
	},
	user: (state = initialAuthState.user, action) => {
		switch (action.type) {
		case LOAD_AUTH_USER: {
			return getStateInProgress();
		}
		case LOAD_AUTH_USER_SUCCESS: {
			const {response} = action.payload;
			return getStateSuccess(response);
		}
		case LOGIN_SUCCESS: {
			const {user} = action.payload;
			return getStateSuccess(user);
		}
		case LOGOUT: {
			return getStateInitial();
		}
		case LOAD_AUTH_USER_ERROR: {
			const {error} = action.payload;

			return getStateError(error);
		}
		case LOAD_AUTH_USER_CLEAR: {
			return getStateInitial();
		}
		default:
			return state;
		}
	}
});

// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-redeclare
export type authReducers = StateType<typeof authReducers>;
