import { createSelector } from 'reselect';
import { Job, JobExecution, JobList } from '../../shared/types';

import { PaginatedResults, createOperationResultSelector, operationSelector } from '@indigo-cloud/common-react';
import { RootState } from '../reducers';

const getJobsSelector = (state: RootState) => {
	return state?.jobs.getJobsOperation;
};

const getJobDetailsforSelected = (state: RootState) => {
	return state?.jobs.getJobDetails;
};

const getJobExecutionDetailsforSelected = (state: RootState) => {
	return state?.jobs.getJobExecutionDetails;
};

const getJobsOperationSelector = (id: string) => {
	return createSelector(getJobsSelector, (state) => {
		return operationSelector<{[pageNumberAndFilters: string]: PaginatedResults<Job>}, Error>(state[id]);
	})
};

const getJobDetailsSelector = (id: string) => {
	return createSelector(getJobDetailsforSelected, (state) => {
		return operationSelector<Job, Error>(state[id]);
	})
};

const getJobExecutionDetailsSelector = (id: string) => {
	return createSelector(getJobExecutionDetailsforSelected, (state) => {
		return operationSelector<JobExecution, Error>(state[id]);
	})
};

const getJobsOperationResultsSelector = (id: string) => createOperationResultSelector(getJobsOperationSelector(id));
const getJobsOperationResultsPageSelector = (pageNo: number, id: string, filters?: Record<string, unknown>) => {
	return createSelector(getJobsOperationResultsSelector(id), (state) => state?.[`${pageNo}_${JSON.stringify(filters)}`]);
}

export const jobsSelectors = {
	getJobDetails: getJobDetailsSelector,
	getJobExecutionDetails: getJobExecutionDetailsSelector,
	getJobsOperation: getJobsOperationSelector,
	getJobsOperationResults: getJobsOperationResultsSelector,
	getJobsOperationResultsPage: getJobsOperationResultsPageSelector
};
