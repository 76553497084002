import React, {useImperativeHandle, useMemo, useRef, useState} from 'react';
import {Field, FieldAttributes, Form, Formik, FormikProps} from 'formik';
import {CheckboxWithLabel, Select, TextField} from 'formik-material-ui';
import {useDispatch, useSelector} from 'react-redux';

import * as PropTypes from 'prop-types';
import {Box, Checkbox, CheckboxProps, FormControlLabel, FormControlLabelProps, Typography} from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

import styles from './EditorForm.component.module.scss';

import _ from 'lodash';

import * as Yup from 'yup';
import MenuItem from '@mui/material/MenuItem';

export type EditorFormInputType = 'text' | 'select' | 'number' | 'checkbox';

export interface EditorFormComponentProperties<FormValues = any> {
	actionCreators: {
		create: (formValues: FormValues, formikPromise: {resolve: () => void; reject: () => void}, createAnother: boolean) => void
		update: (formValues: FormValues, formikPromise: {resolve: () => void; reject: () => void}) => void
	},

	dataCySelectors?: {
		form?: string;
		createAnother?: {
			formControl?: string;
			input?: string;
			label?: string;
		}
	},
	fieldConfig: {
		[K in keyof FormValues]: {
			schema: Yup.BaseSchema;
			type?: EditorFormInputType;
			selectors?: {
				formControl?: string;
				formControlLabel?: string;
				input?: string;
			},
			options?: string[];
			isDisabled?: boolean;
			initialValue?: FormValues[K];

			label?: string;
			inputComponent?: React.Component;
			shouldRenderField?: boolean;
			renderField?: (properties: any) => JSX.Element;
		} & Partial<FieldAttributes<any>>
	},
	createAnotherConfig?: {
		isEnabled?: boolean;
		inputProps?: Partial<CheckboxProps>;
		formControlProps?: Partial<FormControlLabelProps>;
	},
	modelToUpdate: FormValues;
	selectors: {
		updateOperation: any;
		createOperation: any;
	},

	mapFormValuesToActionPayload?: (formValues: FormValues) => any,
	onFormIsValidChanged?: (value: boolean) => void;
	onFormIsTouchedChanged?: (value: boolean) => void;
	onFormIsSubmittingChanged?: (value: boolean) => void;

	onFormReset?: () => void;
}

export type EditorFormReference = {
	formik: FormikProps<any> | null;
};

const getErrorFromOperation = (operation: any) => typeof operation?.error?.error === 'string' ? operation.error.error : operation?.error?.error?.message;

export const EditorForm = React.forwardRef<EditorFormReference, EditorFormComponentProperties>(({
	actionCreators,
	createAnotherConfig,
	dataCySelectors,
	fieldConfig,
	mapFormValuesToActionPayload,
	modelToUpdate,
	selectors,
	onFormIsValidChanged,
	onFormIsTouchedChanged,
	onFormIsSubmittingChanged,
	onFormReset
}, reference) => {

	const isUpdateMode = !!modelToUpdate;

	const dispatch = useDispatch();

	const updateOperation = useSelector(selectors.updateOperation);
	const createOperation = useSelector(selectors.createOperation);

	const operationSnackbarError = getErrorFromOperation(isUpdateMode ? updateOperation : createOperation);

	const [isCheckedCreateAnother, setIsCreateAnotherChecked] = useState(false);
	const keysFields = Object.keys(fieldConfig);

	const initialValues = useMemo(() => {
		return keysFields.reduce((result, keyFieldConfig) => {
			const {initialValue, type = 'text'} = fieldConfig[keyFieldConfig];

			const updated = result;
			const defaultValue = (type === 'select' || type === 'text') ? '' : 0;

			updated[keyFieldConfig] = isUpdateMode ? modelToUpdate[keyFieldConfig] : initialValue || defaultValue;
			return updated;
		}, {} as any);
	}, [keysFields, isUpdateMode]);

	const schemaFields = useMemo(() => {
		return keysFields.reduce((result, keyFieldConfig) => {
			const {schema} = fieldConfig[keyFieldConfig];

			const updated = result;

			updated[keyFieldConfig] = schema;
			return updated;
		}, {} as any);
	}, [keysFields, isUpdateMode, fieldConfig]);

	const schema = Yup.object().shape(schemaFields);

	const referenceFormik = useRef<EditorFormReference['formik']>(null);
	const referenceFormIsValid = useRef(false);
	const referenceFormIsSubmitting = useRef(false);
	const referenceFormIsTouched = useRef(false);

	useImperativeHandle(reference, () => ({
		formik: referenceFormik.current
	}));

	const onChangeCheckboxCreateAnother = (_: any, value: boolean) => {
		setIsCreateAnotherChecked(value);
	}

	return (

		<Formik
			innerRef={referenceFormik}
			initialValues={initialValues}
			enableReinitialize
			validateOnMount
			validateOnChange
			validationSchema={schema}
			isInitialValid={false}
			onSubmit={async (values) => {
				try {
					await new Promise<void>((resolve, reject) => {

						const payload = mapFormValuesToActionPayload?.(values) || values;

						if (isUpdateMode) {
							dispatch(actionCreators.update(payload, {reject, resolve}));
						} else {
							dispatch(actionCreators.create(payload, {reject, resolve}, isCheckedCreateAnother));
						}

						if (!isUpdateMode && isCheckedCreateAnother) {
							referenceFormik.current?.setFieldValue('createAnother', true);
						}
					});

					referenceFormik.current?.resetForm();
					onFormReset?.();
				}
				catch (error) {
					console.log('An error occurred while submitting the form', error);
				}
			}}
		>
			{
				({isValid, isSubmitting, touched}) => {
					const isFormTouched = Object.keys(touched).every((key) => {
						return touched[key] === true;
					});

					if (isFormTouched !== referenceFormIsTouched.current) {
						onFormIsTouchedChanged?.(isFormTouched);
					}
					if (isValid !== referenceFormIsValid.current) {
						onFormIsValidChanged?.(isValid);
					}
					if (isSubmitting !== referenceFormIsSubmitting.current) {
						onFormIsSubmittingChanged?.(isSubmitting);
					}

					// [DF - 17/06/21]: Accessing this form component's exposed imperative handle from parent page component (eg refFirmwareEditorForm.current.formik) will NOT update on formik prop changes causing stale form state in the parent. Hence manual onChange detection
					referenceFormIsTouched.current = isFormTouched;
					referenceFormIsValid.current = isValid;
					referenceFormIsSubmitting.current = isSubmitting;

					return (
						<Form data-cy={dataCySelectors?.form}>
							{!!operationSnackbarError && <Box paddingY={1}>

								<Typography variant="body2" className="MuiFormHelperText-root Mui-error" color="error">
									{operationSnackbarError}
								</Typography>
							</Box>}
							{
								keysFields.filter((keyField) => {
									const {
										shouldRenderField
									} = fieldConfig[keyField];

									return shouldRenderField !== false;
								}).map((keyField) => {

									const {
										type = 'text',
										name,
										isDisabled,
										initialValue,
										label,
										inputComponent: componentOverride,
										renderField,




										selectors,
										options,
										...properties
									} = fieldConfig[keyField];

									let component;

									if (componentOverride) {
										component = componentOverride;
									} else if (type === 'select') {
										component = Select;
									} else if (type === 'checkbox') {
										component = CheckboxWithLabel
									} else {
										component = TextField;
									}

									const selectOptionsContent = options?.map((cdn) => <MenuItem key={cdn} value={cdn}>{cdn}</MenuItem>)

									const fieldProperties = {
										children: selectOptionsContent,

										className: styles.field,
										component: component,
										disabled: isDisabled || isSubmitting,

										name: keyField,

										// [07-01-22] MUI uses 'text' for select dropdowns instead of a native <select> element
										type: type === 'select' ? 'text' : type,

										...properties
									};

									const content = <Box paddingBottom={2} key={name}>
										<FormControl variant="standard" className={styles.formControl}  {...(selectors?.formControl ? {'data-cy': selectors.formControl} : {})}>
											<FormHelperText {...(selectors?.formControlLabel ? {'data-cy': selectors?.formControlLabel} : {})}>{_.startCase(label || fieldProperties.name)}</FormHelperText>
											<Field {...fieldProperties} displayEmpty {...(selectors?.input ? {'data-cy': selectors.input} : {})} />
										</FormControl>

									</Box>;

									const contentCustomised = renderField?.({
										...fieldProperties,
										children: content,
										isSubmitting
									});



									return contentCustomised ? contentCustomised : content;
								})
							}
							{/* // TODO: Add tests around conditional rendering of createAnother (for create mode only) */}
							{isUpdateMode === false && createAnotherConfig?.isEnabled !== false && <Box paddingBottom={2} className={styles.checkboxRight} data-cy={dataCySelectors?.createAnother?.formControl}>
								<FormControlLabel {...createAnotherConfig?.formControlProps} data-cy={dataCySelectors?.createAnother?.label} control={<Checkbox {...createAnotherConfig?.inputProps} data-cy={dataCySelectors?.createAnother?.input} value={isCheckedCreateAnother} onChange={onChangeCheckboxCreateAnother} color={'primary'} />} label="Create Another?" />
							</Box>}

						</Form>
					)
				}
			}
		</Formik>
	)
});

EditorForm.displayName = 'Editor Form';

EditorForm.propTypes = {
	actionCreators: PropTypes.shape({
		create: PropTypes.func.isRequired,
		update: PropTypes.func.isRequired
	}).isRequired,
	createAnotherConfig: PropTypes.object,

	dataCySelectors: PropTypes.object,
	fieldConfig: PropTypes.any.isRequired,
	mapFormValuesToActionPayload: PropTypes.func,
	modelToUpdate: PropTypes.object.isRequired,


	onFormIsSubmittingChanged: PropTypes.func,
	onFormIsTouchedChanged: PropTypes.func,
	onFormIsValidChanged: PropTypes.func,
	onFormReset: PropTypes.func,
	selectors: PropTypes.shape({
		createOperation: PropTypes.object.isRequired,
		updateOperation: PropTypes.object.isRequired

	}).isRequired
};
