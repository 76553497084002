import { Role, isAuthorised, useAuthorisation } from '@indigo-cloud/common-react';
import MaterialTable from '@material-table/core';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FilterListIcon from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { appRoutes } from '../../../App.router';
import { reportActions } from '../../../store/actions';
import { reportSelectors } from '../../../store/selectors';
import { Report } from '../../types';
import styles from './ReportsTable.component.module.scss';


import { InputAdornment, TextField } from '@mui/material';
import moment from 'moment';
import { theme } from '../../../App.theme';
import { navigateToReportIdentifier } from '../../utils';
import { ReportInfo } from '../ReportInfo';

const columnStyle: React.CSSProperties = { fontFamily: theme.typography.fontFamily, justifyContent: 'center' };

export const reportsTableDataSelectors = {
	container: 'ReportsTable-container'
};

export const ReportsTable: React.FC = () => {
	const operationListReports = useSelector(reportSelectors.listReportsOperation);

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(reportActions.listReports());
	}, []);

	const history = useHistory();

	const data = operationListReports?.result?.data || [];

	const reference = useRef<any>(null);

	const [filteredData, setFilteredData] = useState([]);

	const userAuthorisationGroups = useAuthorisation();

	return (
		<div data-cy={reportsTableDataSelectors.container} id={styles.container} >

			<MaterialTable<Report>
				title="Reports"
				tableRef={reference}
				columns={[
					{ align: 'center', cellStyle: columnStyle, field: 'id', headerStyle: columnStyle, title: 'Report ID' },
					{ align: 'center', cellStyle: columnStyle, field: 'name', headerStyle: columnStyle, title: 'Report Name' },
					{
						align: 'center',
						cellStyle: columnStyle,
						field: 'createdAt',
						headerStyle: columnStyle,
						render: (report) => {

							return (
								<div>
									{`${moment(report.createdAt).format('DD/MM/YYYY')} (${moment(report.createdAt).fromNow()})`}
								</div>

							);

						},
						title: 'Created At'

					},
					{
						align: 'center',
						cellStyle: columnStyle,
						field: 'updatedAt',
						headerStyle: columnStyle,
						render: (report) => {
							return (
								<div>
									{report.updatedAt ? `${moment(report.updatedAt).format('DD/MM/YYYY')} (${moment(report.updatedAt).fromNow()})` : 'N/A'}
								</div>
							);

						},
						title: 'Updated At'

					},
					// ...(onClickUpdate ? [
					// 	{
					// 		Cell: operationUpdateCell,
					// 		Header: '',
					// 		accessor: (d: Firmware) => d,
					// 		disableFilters: true,
					// 		id: 'update'
					// 	}
					// ] : []),
					isAuthorised([Role.Suota, Role.Standard, Role.Firmware, Role.Admin], userAuthorisationGroups) ? {
						field: 'delete',
						render: (report) => {
							return <div style={{ display: 'flex', flexDirection: 'row' }}>
									 <Tooltip title='Update Report'>
									<IconButton
										onClick={() => {

											navigateToReportIdentifier(report!, history);
										}}
										size="large">
										<EditIcon />
									</IconButton>
								</Tooltip>
								<Tooltip title='Delete Report'>
									<IconButton
										onClick={() => {

											history.push({
												pathname: appRoutes.ReportsDelete.replace(':reportId', report.id)
											});
										}}
										size="large">
										<DeleteIcon />
									</IconButton>
								</Tooltip>
							</div>
						},
						title: 'Actions',
						width: 120
					} : {}


					// { align: 'center', cellStyle: columnStyle, field: 'serialNumber', headerStyle: columnStyle, title: 'Serial Number' },
					// { align: 'center', cellStyle: columnStyle, field: 'softwareVersion', headerStyle: columnStyle, title: 'Software Version' },
					// { align: 'center', cellStyle: columnStyle, field: 'macAddress', headerStyle: columnStyle, title: 'MAC Address' },
					// { align: 'center', cellStyle: columnStyle, field: 'oui', headerStyle: columnStyle, title: 'OUI' },
					// { align: 'center', cellStyle: columnStyle, field: 'productClass', headerStyle: columnStyle, title: 'Product Class' },
					// {
					// 	align: 'center',
					// 	cellStyle: columnStyle,
					// 	headerStyle: columnStyle,

					// 	render: (report) => {
					// 		return (
					// 			<div className={styles.containerRowActions}>
					// 				<Tooltip title={'Open in a new tab'}>

					// 					<Link to={getReportIdentifierUrl(report)} target="_blank" onClick={(error) => { error.stopPropagation() }}>
					// 						<IconButton size="large">
					// 							<OpenInBrowserIcon />
					// 						</IconButton >
					// 					</Link>
					// 				</Tooltip>
					// 			</div>
					// 		);

					// 	},
					// 	title: 'Actions',
					// 	width: 150
					// }
				]}
				components={{
					// 2022-02-08 [DEF]:  https://github.com/material-table-core/website/blob/dc91f769de59d14adc4a921c9a15248e677cb38c/demos/filter/custom-filter-row.mdx#live-demo
					FilterRow: (rowProperties) => {

						const { actionsColumnIndex, columns, onFilterChanged, selection, hasDetailPanel } = rowProperties;

						return (
							<tr style={{ 'borderBottom': '1px solid rgba(224, 224, 224, 1)' }} key={actionsColumnIndex}>
								{selection && <td></td>}
								{hasDetailPanel && <td></td>}
								{columns.map((col: any) => {


									if (col.field) {
										return (
											<th style={{ 'padding': '24px 24px 24px 8px' }} key={col.field}>
												<TextField
													variant="standard"
													InputProps={{
														startAdornment: (
															<InputAdornment position="start">
																<FilterListIcon />
															</InputAdornment>
														)
													}}
													id={col.field}
													onChange={event => onFilterChanged(col.tableData.id, event.target.value)}
												/>
											</th>
										);
									}

								})}
							</tr>
						);
					}
				}}
				options={{
					draggable: false,
					emptyRowsWhenPaging: false,
					filterCellStyle: { textAlign: 'center' },
					filtering: true,
					pageSize: 20,
					pageSizeOptions: [20, 50, 100, 500, 1000],
					searchFieldVariant: 'standard'


				}}
				data={data}
				onRowClick={(_, report) => {
					// navigateToReportIdentifier(report!, history);
				}}
				detailPanel={(rowData) => {
					const { id } = rowData as unknown as Report & { tableData: any };
					return (
						<ReportInfo id={id} />
					)
			 }}
			/>
		</div>);
};
