import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import React, { useContext } from 'react';
import Loadable from 'react-loadable';
import { Route, Switch, useLocation } from 'react-router-dom';

import { BackdropLoader, NavigationBreadcrumbs, PrivateRoute, Role } from '@indigo-cloud/common-react';

import styles from './App.router.module.scss';
import { AwsExportsContext } from './components';

export enum AppRouteNames {
	Dashboard = 'Dashboard',
	Release = 'Release',
	ReleaseCreate = 'ReleaseCreate',
	ReleaseDelete = 'ReleaseDelete',
	ReleaseUpdate = 'ReleaseUpdate',
	ReleaseView = 'ReleaseView',
	RuleCreate = 'RuleCreate',
	RuleDelete = 'RuleDelete',
	RuleUpdate = 'RuleUpdate',
	RuleView = 'RuleView',
	NotFound = 'NotFound',
}

export const appRoutesReleaseRules = {
	[AppRouteNames.ReleaseCreate]: '/suota/release/create',
	[AppRouteNames.ReleaseDelete]: '/suota/release/delete/:targetFw',
	[AppRouteNames.ReleaseUpdate]: '/suota/release/update/:targetFw',
	[AppRouteNames.ReleaseView]: '/suota/release/:targetFw/view'
}

export const appRoutesReleaseReleases = {
	[AppRouteNames.RuleCreate]: '/suota/release/rule/:targetFw?/create',
	[AppRouteNames.RuleDelete]: '/suota/release/rule/delete/:data',
	[AppRouteNames.RuleUpdate]: '/suota/release/rule/update/:hubFw/:swvid/:hubModel/:targetFw',
	[AppRouteNames.RuleView]: '/suota/release/rule/:hubFw/:swvid/:hubModel/:targetFw/view'
}

export const appRoutes = {
	[AppRouteNames.Dashboard]: '/suota',
	[AppRouteNames.Release]: '/suota/release',
	...appRoutesReleaseRules,
	...appRoutesReleaseReleases,

	[AppRouteNames.NotFound]: '/suota/page-not-found'
}


export const navigationItems = [
	{
		name: AppRouteNames.NotFound,
		shouldHideLink: true
	},
	{
		name: AppRouteNames.Dashboard,
		shouldHideLink: true
	},
	{
		icon: SystemUpdateAltIcon,
		name: AppRouteNames.Release,
		roles: [Role.Readonly, Role.Standard, Role.Suota, Role.Firmware, Role.Admin]
	},
	{
		name: AppRouteNames.ReleaseCreate,
		shouldHideLink: true
	},
	{
		name: AppRouteNames.ReleaseUpdate,
		shouldHideLink: true
	},
	{
		name: AppRouteNames.ReleaseDelete,
		shouldHideLink: true
	},
	{
		name: AppRouteNames.ReleaseView,
		shouldHideLink: true
	},
	{
		name: AppRouteNames.RuleCreate,
		shouldHideLink: true
	},
	{
		name: AppRouteNames.RuleUpdate,
		shouldHideLink: true
	},
	{
		name: AppRouteNames.RuleDelete,
		shouldHideLink: true
	},
	{
		name: AppRouteNames.RuleView,
		shouldHideLink: true
	}
];


export type AppRouteKey = keyof typeof appRoutes;
export const appRouteNames: AppRouteKey[] = [];

for (const appRouteKey in appRoutes) {
	appRouteNames.push(appRouteKey as AppRouteKey);
}

const Loading = () => <BackdropLoader isLoading />;
export const pages = {
	[AppRouteNames.Dashboard]: Loadable({
		loader: () => import('./pages/Dashboard/Dashboard.page'),
		loading: Loading
	}),
	[AppRouteNames.Release]: Loadable({
		loader: () => import('./pages/ReleaseRule/ReleaseRuleListEditor.page'),
		loading: Loading
	}),
	[AppRouteNames.ReleaseCreate]: Loadable({
		loader: () => import('./pages/ReleaseRule/pages/ReleaseEditor/ReleaseEditor.page'),
		loading: Loading
	}),
	[AppRouteNames.ReleaseDelete]: Loadable({
		loader: () => import('./pages/ReleaseRule/pages/ReleaseDelete/ReleaseDelete.page'),
		loading: Loading
	}),
	[AppRouteNames.ReleaseUpdate]: Loadable({
		loader: () => import('./pages/ReleaseRule/pages/ReleaseEditor/ReleaseEditor.page'),
		loading: Loading
	}),
	[AppRouteNames.ReleaseView]: Loadable({
		loader: () => import('./pages/ReleaseRule/pages/ReleaseView/ReleaseView.page'),
		loading: Loading
	}),
	[AppRouteNames.RuleCreate]: Loadable({
		loader: () => import('./pages/ReleaseRule/pages/RuleEditor/RuleEditor.page'),
		loading: Loading
	}),
	[AppRouteNames.RuleDelete]: Loadable({
		loader: () => import('./pages/ReleaseRule/pages/RuleDelete/RuleDelete.page'),
		loading: Loading
	}),
	[AppRouteNames.RuleUpdate]: Loadable({
		loader: () => import('./pages/ReleaseRule/pages/RuleEditor/RuleEditor.page'),
		loading: Loading
	}),
	[AppRouteNames.RuleView]: Loadable({
		loader: () => import('./pages/ReleaseRule/pages/RuleView/RuleView.page'),
		loading: Loading
	}),
	[AppRouteNames.NotFound]: Loadable({
		loader: () => import('./pages/NotFound/NotFound.page'),
		loading: Loading
	})
};

interface AppRouterProperties {}

export const AppRouter: React.FC<AppRouterProperties> = () => {
	const contextAwsExports = useContext(AwsExportsContext);

	const location = useLocation();

	return (
		<div
			className={styles.container}
		>
			<NavigationBreadcrumbs routes={appRoutes} />
			<Switch location={location as any}>
				{/* Private routes */}
				<PrivateRoute roles={[Role.Readonly, Role.Standard, Role.Suota, Role.Firmware, Role.Admin]} path={appRoutes.Dashboard} exact component={pages[AppRouteNames.Dashboard]} awsExports={contextAwsExports}	 />
				<PrivateRoute roles={[Role.Readonly, Role.Standard, Role.Suota, Role.Firmware, Role.Admin]} path={appRoutes.Release} component={pages[AppRouteNames.Release]} awsExports={contextAwsExports} />
				{/* Fallback catch-all route */}
				<Route component={pages[AppRouteNames.NotFound]} />
			</Switch>
		</div>
	);
};

AppRouter.propTypes = {};
