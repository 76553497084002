import { Box, MenuItem } from '@mui/material';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { SelectProps } from '@mui/material/Select';
import { Field, Form, Formik, FormikProps } from 'formik';
import { TextField } from 'formik-material-ui';
import * as PropTypes from 'prop-types';
import React, { useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { deviceGroupsActions } from '../../../../../store/actions';
import styles from './AddDeviceGroupInputModalForm.component.module.scss';

import { useIsMounted } from '@indigo-cloud/common-react';

export interface AddDeviceGroupInputModalFormProperties {
	formDisplay?: boolean;
	onFormIsValidChanged?: (value: boolean) => void;
	onFormIsTouchedChanged?: (value: boolean) => void;
	onFormIsSubmittingChanged?: (value: boolean) => void;
	onFormReset?: () => void;
}
export interface DeviceFirmwareUpdateInputModalFormState {
	errorMessage?: string;
}

export const AddDeviceGroupInputModalFormDataSelectors = {
	form: 'AddDeviceGroupInputModalForm-form',
	formControlDescription: 'AddDeviceGroupInputModalForm-form-control-description',
	formControlType: 'AddDeviceGroupInputModalForm-form-control-type',
	formControlName: 'AddDeviceGroupInputModalForm-form-control-name',
	formControlQuery: 'AddDeviceGroupInputModalForm-form-control-query'
};

export type AddDeviceGroupInputModalFormReference = {
	formik: FormikProps<AddDeviceGroupInputModalFormValues> | null;
	formReset?: () => void;
};

export type AddDeviceGroupInputModalFormValues = {
	name: string;
	description: string;
	type: string;
	query: string;
};

export const AddDeviceGroupInputModalForm = React.forwardRef<AddDeviceGroupInputModalFormReference, AddDeviceGroupInputModalFormProperties>(({
	onFormIsTouchedChanged,
	onFormIsSubmittingChanged,
	onFormIsValidChanged,
	onFormReset,
	formDisplay = true
}, reference) => {
	const schema = useMemo(
		() =>
			Yup.object().shape({
				description: Yup.string(),
				name: Yup.string()
					.required('Required').matches(/^[\w-]+$/i, 'Invalid name. Accepted characters A-Z a-z 0-9 - _'),
				type: Yup.string()
					.required('Required'),
				query: Yup.string().when('type', {
					is: (value: string) => value === 'DYNAMIC',
					then: Yup.string().required('Required')
				})
			}),
		[]
	);

	const dispatch = useDispatch();
	const isMounted = useIsMounted();

	const [showHelp, setShowHelp] = useState<boolean>(false);
	const [queryValue, setQueryValue] = useState<string>('');
	
	const referenceFormik = useRef<AddDeviceGroupInputModalFormReference['formik']>(null);
	const referenceFormIsValid = useRef(false);
	const referenceFormIsSubmitting = useRef(false);
	const referenceFormIsTouched = useRef(false);

	const handleChange = (event: any) => {
		setQueryValue(event.target.value);
		referenceFormik.current?.handleChange(event);
	}

	const handleReset = (event: any) => {
		setQueryValue('');
		setShowHelp(false);
	};

	useImperativeHandle(reference, () => ({
		formReset: referenceFormik.current?.resetForm,
		formik: referenceFormik.current
	}));

	return (
		<Formik<AddDeviceGroupInputModalFormValues>
			innerRef={referenceFormik}
			initialValues={{
				description: '',
				name: '',
				query: '',
				type: 'STATIC'
			  }}
			enableReinitialize={true}
			validateOnMount
			validateOnChange
			validationSchema={schema}
			onReset={handleReset}
			onSubmit={async (values, { resetForm }) => {
				try {
					await new Promise((resolve, reject) => {
						values = {...values, query: values.query.trim()};
						dispatch(deviceGroupsActions.addDynnamicGroup(values, { reject, resolve }));
					});
					onFormReset?.();
					dispatch(deviceGroupsActions.loadDeviceGroups());
				}
				catch (error) {
					console.log('An error occurred while submitting the form', error);
				}
			 }}
		>
			{({ isValid, touched, isSubmitting, values }) => {
				const isFormTouched = Object.keys(values as any).some((key) => {
					return values[key as keyof typeof values].length;
				});
				if (isFormTouched !== referenceFormIsTouched.current) {
					setTimeout(() => {
						if (isMounted()) {
							onFormIsTouchedChanged?.(isFormTouched)
						}
					}, 1);
				}
				if (isValid !== referenceFormIsValid.current) {
					setTimeout(() => onFormIsValidChanged?.(isValid), 1);
				}
				if (isSubmitting !== referenceFormIsSubmitting.current) {
					setTimeout(() => onFormIsSubmittingChanged?.(isSubmitting), 1);
				}

				referenceFormIsTouched.current = isFormTouched;
				referenceFormIsValid.current = isValid;
				referenceFormIsSubmitting.current = isSubmitting;

				return (
					<Form data-cy={AddDeviceGroupInputModalFormDataSelectors.form} className={formDisplay ? styles.formDisplay : styles.formHide} onKeyDown={(keyEvent) => {

						if (keyEvent.code === 'Enter') {
							keyEvent.preventDefault();
						}
					}}>

						<Box sx={{ minHeight: '500px', mt: 1 }} className={styles.box}>
							<Field name="name" component={TextField} placeholder="Name" data-cy={AddDeviceGroupInputModalFormDataSelectors.formControlName} fullWidth className={styles.input} />
							<Field name="description" component={TextField} placeholder="Description" data-cy={AddDeviceGroupInputModalFormDataSelectors.formControlDescription} fullWidth className={styles.input} />
							<Field
								as="select"
								name="type"
								component={TextField}
								select={true}
								fullWidth
								className={styles.input}
								SelectProps={{
									'data-cy': AddDeviceGroupInputModalFormDataSelectors.formControlType
								}}
							>
								<MenuItem value={'STATIC'}>Static</MenuItem>
								<MenuItem value={'DYNAMIC'}>Dynamic</MenuItem>
							</Field>
							<Field name="query" type="query" component={TextField} disabled={values.type === 'STATIC'} placeholder="Query" data-cy={AddDeviceGroupInputModalFormDataSelectors.formControlQuery} fullWidth  className={styles.input} value={values.type != 'STATIC' ? queryValue : ''} onChange={(event: any) => handleChange(event, 'query')} onFocus={(event: any) => { setShowHelp(true);}} />
							<List sx={{ bgcolor: 'background.paper', display: showHelp && values.type != 'STATIC' ? 'block' : 'none', width: '100%' }}>
								<ListItem alignItems="flex-start" disablePadding>
									<ListItemButton onClick={(event) => { setQueryValue('connectivity.connected:');}}>
            							<ListItemText secondary="connectivity.connected" />
           			 				</ListItemButton>
								</ListItem>
								<Divider component="li" />
								<ListItem alignItems="flex-start" disablePadding>
									<ListItemButton onClick={(event) => { setQueryValue('connectivity.disconnectReason:');}}>
            							<ListItemText secondary="connectivity.disconnectReason" />
           			 				</ListItemButton>
								</ListItem>
								<Divider component="li" />
								<ListItem alignItems="flex-start" disablePadding>
									<ListItemButton onClick={(event) => { setQueryValue('connectivity.timestamp:');}}>
            							<ListItemText secondary="connectivity.timestamp" />
           			 				</ListItemButton>
								</ListItem>
								<Divider component="li" />
								<ListItem alignItems="flex-start" disablePadding>
									<ListItemButton onClick={(event) => { setQueryValue('thingGroupNames:');}}>
            							<ListItemText secondary="thingGroupNames" />
           			 				</ListItemButton>
								</ListItem>
								<Divider component="li" />
								<ListItem alignItems="flex-start" disablePadding>
									<ListItemButton onClick={(event) => { setQueryValue('thingId:');}}>
            							<ListItemText secondary="thingId" />
           			 				</ListItemButton>
								</ListItem>
								<Divider component="li" />
								<ListItem alignItems="flex-start" disablePadding>
									<ListItemButton onClick={(event) => { setQueryValue('thingName:');}}>
            							<ListItemText secondary="thingName" />
           			 				</ListItemButton>
								</ListItem>
								<Divider component="li" />
								<ListItem alignItems="flex-start" disablePadding>
									<ListItemButton onClick={(event) => { setQueryValue('thingTypeName:');}}>
            							<ListItemText secondary="thingTypeName" />
           			 				</ListItemButton>
								</ListItem>
							</List>
						</Box>
					</Form>
				);
			}}
		</Formik>
	);
});

AddDeviceGroupInputModalForm.displayName = 'Add & Remove Devices';

AddDeviceGroupInputModalForm.propTypes = {
	formDisplay: PropTypes.any,
	onFormIsSubmittingChanged: PropTypes.func,
	onFormIsTouchedChanged: PropTypes.func,
	onFormIsValidChanged: PropTypes.func,
	onFormReset: PropTypes.func
};
