/* eslint sort-keys: 0 */
import { Action, ActionType, PayloadAction } from 'typesafe-actions';

import { FormikFormSubmitPromise, PaginatedResults, createAction } from '@indigo-cloud/common-react';
import { AddDynamicDeviceGroupResponse, DeviceGroup, DeviceGroupActivationWindow, DeviceGroupTriggerFirmwareUpdateResponse, DevicesResponse, Job, ScheduleConfig, Thing, ThingList } from '../../../shared';
import {
	ADD_DYNAMIC_DEVICE_GROUP,
	ADD_DYNAMIC_DEVICE_GROUP_ERROR,
	ADD_DYNAMIC_DEVICE_GROUP_SUCCESS,
	DELETE_DEVICE_GROUP,
	DELETE_DEVICE_GROUP_ERROR,
	DELETE_DEVICE_GROUP_SUCCESS,
	LOAD_DEVICE_GROUPS,
	LOAD_DEVICE_GROUPS_ERROR,
	LOAD_DEVICE_GROUPS_INFO,
	LOAD_DEVICE_GROUPS_SUCCESS,
	LOAD_DEVICE_GROUP_DEVICES,
	LOAD_DEVICE_GROUP_DEVICES_CLEAR,
	LOAD_DEVICE_GROUP_DEVICES_ERROR,
	LOAD_DEVICE_GROUP_DEVICES_SUCCESS,
	LOAD_DEVICE_GROUP_INFO_ERROR,
	LOAD_DEVICE_GROUP_INFO_SUCCESS,
	LOAD_DEVICE_GROUP_JOBS,
	LOAD_DEVICE_GROUP_JOBS_CLEAR,
	LOAD_DEVICE_GROUP_JOBS_ERROR,
	LOAD_DEVICE_GROUP_JOBS_SUCCESS,
	TRIGGER_DEVICE_GROUP_ADD_DEVICES,
	TRIGGER_DEVICE_GROUP_ADD_DEVICES_ERROR,
	TRIGGER_DEVICE_GROUP_ADD_DEVICES_SUCCESS,
	TRIGGER_DEVICE_GROUP_FIRMWARE_UPDATE,
	TRIGGER_DEVICE_GROUP_FIRMWARE_UPDATE_ERROR,
	TRIGGER_DEVICE_GROUP_FIRMWARE_UPDATE_SUCCESS,
	TRIGGER_DEVICE_GROUP_REMOVE_DEVICES,
	TRIGGER_DEVICE_GROUP_REMOVE_DEVICES_ERROR,
	TRIGGER_DEVICE_GROUP_REMOVE_DEVICES_SUCCESS
} from '../../constants';
import { AddDeviceGroupInputModalFormValues } from '../../../shared/components/AddDeviceGroup/components/AddDeviceGroupInputModalForm';
import { DeviceFirmwareUpdateInputModalFormValues } from 'src/shared/components/DeviceFirmwareUpdateInputModalForm';

export const deviceGroupsActions = {
	addDynnamicGroup: (model: AddDeviceGroupInputModalFormValues, formikPromise: FormikFormSubmitPromise): PayloadAction<typeof ADD_DYNAMIC_DEVICE_GROUP, { formikPromise: typeof formikPromise; model: typeof model;  }> => createAction(ADD_DYNAMIC_DEVICE_GROUP, { formikPromise, model  }),
	addDynnamicGroupError: <ErrorType extends Error>(error: ErrorType): PayloadAction<typeof ADD_DYNAMIC_DEVICE_GROUP_ERROR, {  error: ErrorType }> =>
	   createAction(ADD_DYNAMIC_DEVICE_GROUP_ERROR, {error}),
	 addDynnamicGroupSuccess:  (
	   response: AddDynamicDeviceGroupResponse
	): PayloadAction<typeof ADD_DYNAMIC_DEVICE_GROUP_SUCCESS, { response: typeof response }> =>
	   createAction(ADD_DYNAMIC_DEVICE_GROUP_SUCCESS, { response }),
	/**
	/** Groups  */
	loadDeviceGroups: (): Action<typeof LOAD_DEVICE_GROUPS> => createAction(LOAD_DEVICE_GROUPS),
	loadDeviceGroupsError: <ErrorType extends Error>(error: ErrorType): PayloadAction<typeof LOAD_DEVICE_GROUPS_ERROR, {error: ErrorType}> =>
		createAction(LOAD_DEVICE_GROUPS_ERROR, {error}),
	loadDeviceGroupsSuccess:  (
		response: DevicesResponse
	): PayloadAction<typeof LOAD_DEVICE_GROUPS_SUCCESS, DevicesResponse> =>
		createAction(LOAD_DEVICE_GROUPS_SUCCESS, response),

	/** Devices  */
	loadDeviceGroupDevices: ({name, limit, cursor, pageIndex, filters}: {name: string; filters?: Record<string, unknown>, limit?: number, cursor?: string, pageIndex?: number}): PayloadAction<typeof LOAD_DEVICE_GROUP_DEVICES, { name: typeof name; cursor: typeof cursor, filters: typeof filters, limit: typeof limit, pageIndex: typeof pageIndex }> => createAction(LOAD_DEVICE_GROUP_DEVICES, {
		name,
		limit,
		cursor,
		pageIndex,
		filters
	}),
	loadDeviceGroupDevicesClear: (name: string): PayloadAction<typeof LOAD_DEVICE_GROUP_DEVICES_CLEAR, {name: typeof name}> => createAction(LOAD_DEVICE_GROUP_DEVICES_CLEAR, {name}),
	loadDeviceGroupDevicesError: <ErrorType extends Error>(error: ErrorType, deviceGroup: string): PayloadAction<typeof LOAD_DEVICE_GROUP_DEVICES_ERROR, {name: typeof deviceGroup, error: ErrorType}> =>
		createAction(LOAD_DEVICE_GROUP_DEVICES_ERROR, { name: deviceGroup, error }),
	loadDeviceGroupDevicesSuccess: (
		response: PaginatedResults<Thing>,
		name: string,
		pageIndex: number,
		filters?: Record<string, unknown>
	): PayloadAction<typeof LOAD_DEVICE_GROUP_DEVICES_SUCCESS, { name: typeof name, response: typeof response; pageIndex: typeof pageIndex; filters: typeof filters }> =>
		createAction(LOAD_DEVICE_GROUP_DEVICES_SUCCESS, { name, response, pageIndex, filters }),

	/** Info  */
	loadDeviceGroupInfo: (name: string): PayloadAction<typeof LOAD_DEVICE_GROUPS_INFO, { name: typeof name }> => createAction(LOAD_DEVICE_GROUPS_INFO, {
		name
	}),
	loadDeviceGroupInfoError: <ErrorType extends Error>(id: string, error: ErrorType): PayloadAction<typeof LOAD_DEVICE_GROUP_INFO_ERROR, {id: typeof id, error: ErrorType}> =>
		createAction(LOAD_DEVICE_GROUP_INFO_ERROR, {error, id: typeof id}),
	loadDeviceGroupInfoSuccess: (
		id: string,
		response: DeviceGroup
	): PayloadAction<typeof LOAD_DEVICE_GROUP_INFO_SUCCESS, { id: string, response: DeviceGroup }> =>
		createAction(LOAD_DEVICE_GROUP_INFO_SUCCESS, { id, response }),

	/**
	 * DELETE DEVICE GROUP
	 */
	 deleteDeviceGroup: (deviceGroupId: string): PayloadAction<typeof DELETE_DEVICE_GROUP, { deviceGroupId: typeof deviceGroupId }> => createAction(DELETE_DEVICE_GROUP, { deviceGroupId }),
	 deleteDeviceGroupError: (deviceGroupId: string, error: Error): PayloadAction<typeof DELETE_DEVICE_GROUP_ERROR, { deviceGroupId: typeof deviceGroupId; error: typeof error;  }> =>
		 createAction(DELETE_DEVICE_GROUP_ERROR, { error, deviceGroupId }),
	 deleteDeviceGroupSuccess: (deviceGroupId: string, response: any): PayloadAction<typeof DELETE_DEVICE_GROUP_SUCCESS, { deviceGroupId: typeof deviceGroupId;  response: typeof response }> =>
		 createAction(DELETE_DEVICE_GROUP_SUCCESS, {  response, deviceGroupId }),

	/**
	 * LIST JOBS
	 */
	loadDeviceGroupJobs: ({limit, cursor, pageIndex, name, filters}: {filters?: Record<string, unknown>, name: string, limit?: number, cursor?: string, pageIndex?: number}): PayloadAction<typeof LOAD_DEVICE_GROUP_JOBS, {filters: typeof filters, limit?: number, name: typeof name, cursor?: string, pageIndex?: number}> => createAction(LOAD_DEVICE_GROUP_JOBS, { cursor, filters, limit, name, pageIndex}),
	loadDeviceGroupJobsClear: (): PayloadAction<typeof LOAD_DEVICE_GROUP_JOBS_CLEAR, any> => createAction(LOAD_DEVICE_GROUP_JOBS_CLEAR, {}),
	loadDeviceGroupJobsError: (error: Error): PayloadAction<typeof LOAD_DEVICE_GROUP_JOBS_ERROR, { error: typeof error }> =>
		createAction(LOAD_DEVICE_GROUP_JOBS_ERROR, { error }),
	loadDeviceGroupJobsSuccess: (name: string, result: PaginatedResults<Job>, pageIndex: number, filters?: Record<string, unknown>): PayloadAction<typeof LOAD_DEVICE_GROUP_JOBS_SUCCESS, { name:string, pageIndex: number; result: typeof result , filters: typeof filters}> =>
		createAction(LOAD_DEVICE_GROUP_JOBS_SUCCESS, { name, filters, pageIndex, result }),
	
	/**
	 * Firmware update
	 */
	triggerDeviceGroupFirmwareUpdate: (deviceGroup: string, firmwareName: string, shouldForceDowngrade: boolean, activationWindows: (DeviceGroupActivationWindow | Record<string, unknown>)[], abortConfig: DeviceFirmwareUpdateInputModalFormValues['abortConfig'], executionsRolloutConfig: DeviceFirmwareUpdateInputModalFormValues['executionsRolloutConfig'], schedulingConfig: ScheduleConfig, formikPromise: FormikFormSubmitPromise): PayloadAction<typeof TRIGGER_DEVICE_GROUP_FIRMWARE_UPDATE, { deviceGroup: typeof deviceGroup, firmwareName: typeof firmwareName, formikPromise: typeof formikPromise,  shouldForceDowngrade: typeof shouldForceDowngrade, activationWindows: typeof activationWindows, abortConfig: typeof abortConfig, executionsRolloutConfig: typeof executionsRolloutConfig, schedulingConfig: typeof schedulingConfig }> => createAction(TRIGGER_DEVICE_GROUP_FIRMWARE_UPDATE, { deviceGroup, firmwareName, formikPromise, shouldForceDowngrade, activationWindows, abortConfig, executionsRolloutConfig, schedulingConfig }),
	triggerDeviceGroupFirmwareUpdateError: <ErrorType extends Error>(error: ErrorType, deviceGroup: string): PayloadAction<typeof TRIGGER_DEVICE_GROUP_FIRMWARE_UPDATE_ERROR, { deviceGroup: typeof deviceGroup; error: ErrorType, }> =>
		createAction(TRIGGER_DEVICE_GROUP_FIRMWARE_UPDATE_ERROR, {deviceGroup, error}),
	triggerDeviceGroupFirmwareUpdateSuccess:  (
		response: DeviceGroupTriggerFirmwareUpdateResponse,
		deviceGroup: string
	): PayloadAction<typeof TRIGGER_DEVICE_GROUP_FIRMWARE_UPDATE_SUCCESS, { deviceGroup: typeof deviceGroup, response: typeof response }> =>
	   createAction(TRIGGER_DEVICE_GROUP_FIRMWARE_UPDATE_SUCCESS, { deviceGroup, response }),

	/**
	 * add devices to the group
	 */
	triggerDeviceGroupAddDevices: (deviceGroup: string, devices: {thingNames: string[]}, formikPromise: FormikFormSubmitPromise): PayloadAction<typeof TRIGGER_DEVICE_GROUP_ADD_DEVICES, {deviceGroup: typeof deviceGroup, devices: typeof devices, formikPromise: typeof formikPromise }> => createAction(TRIGGER_DEVICE_GROUP_ADD_DEVICES, { deviceGroup, devices, formikPromise }),
	triggerDeviceGroupAddDevicesError: <ErrorType extends Error>(error: ErrorType, deviceGroup: string): PayloadAction<typeof TRIGGER_DEVICE_GROUP_ADD_DEVICES_ERROR, { deviceGroup: typeof deviceGroup; error: ErrorType, }> =>
		createAction(TRIGGER_DEVICE_GROUP_ADD_DEVICES_ERROR, {deviceGroup, error}),
	triggerDeviceGroupAddDevicesSuccess:  (
		response: string,
		deviceGroup: string
	): PayloadAction<typeof TRIGGER_DEVICE_GROUP_ADD_DEVICES_SUCCESS, { response: typeof response, deviceGroup: typeof deviceGroup }> =>
	   createAction(TRIGGER_DEVICE_GROUP_ADD_DEVICES_SUCCESS, { response, deviceGroup }),

	/**
	 * remove devices from the group
	 */
	triggerDeviceGroupRemoveDevices: (deviceGroup: string, devices: {thingNames: string[]}, formikPromise: FormikFormSubmitPromise): PayloadAction<typeof TRIGGER_DEVICE_GROUP_REMOVE_DEVICES, {deviceGroup: typeof deviceGroup, devices: typeof devices, formikPromise: typeof formikPromise }> => createAction(TRIGGER_DEVICE_GROUP_REMOVE_DEVICES, { deviceGroup, devices, formikPromise }),
	triggerDeviceGroupRemoveDevicesError: <ErrorType extends Error>(error: ErrorType, deviceGroup: string): PayloadAction<typeof TRIGGER_DEVICE_GROUP_REMOVE_DEVICES_ERROR, { deviceGroup: typeof deviceGroup; error: ErrorType, }> =>
		createAction(TRIGGER_DEVICE_GROUP_REMOVE_DEVICES_ERROR, {deviceGroup, error}),
	triggerDeviceGroupRemoveDevicesSuccess:  (
		response: string,
		deviceGroup: string
	): PayloadAction<typeof TRIGGER_DEVICE_GROUP_REMOVE_DEVICES_SUCCESS, { response: typeof response, deviceGroup: typeof deviceGroup }> =>
	   createAction(TRIGGER_DEVICE_GROUP_REMOVE_DEVICES_SUCCESS, { response, deviceGroup })
};

/**
 * @see {@link https://github.com/piotrwitek/typesafe-actions#user-content-redux-actions-1}
 */
// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-redeclare
export type deviceGroupsActions = ActionType<typeof deviceGroupsActions>;
