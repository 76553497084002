import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App.component';
import './index.css';
import reportWebVitals from './report-web-vitals';

import { Amplify } from 'aws-amplify';
import { Provider } from 'react-redux';
import awsExports from './aws-exports';
import { store } from './store';
// import { PersistGate } from 'redux-persist/integration/react';

Amplify.configure(awsExports);

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			{/* <PersistGate loading={<BackdropLoader isLoading />} persistor={persistor}> */}
			<App />
			{/* </PersistGate> */}
		</Provider>
	</React.StrictMode>,
	document.querySelector('#root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
