import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { JobDetailsActions } from '../../../store/actions';
import { jobsSelectors } from '../../../store/selectors';
import styles from './JobDetails.component.module.scss';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Details } from '@indigo-cloud/common-react';
import Typography from '@mui/material/Typography';


type JobDetailsProperties = {
	jobId: string;
	agentEndpointId: string;
	executionNumber: number;
};

interface TabPanelProperties {
	children?: React.ReactNode;
	dir?: string;
	index: number;
	value: number;
}

// TODO: Centralise this
export function TabPanel(properties: TabPanelProperties) {
	const { children, value, index, ...other } = properties;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box width="100%">
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

const getTabAttributes = (index: number) => {
	return {
		'aria-controls': `widget-tabpanel-${index}`,
		id: `widget-tabpanel-${index}`
	};
}

export const jobDetailsDataSelectors = {
	container: 'JobDetails-container'
};

export const JobDetails: React.FC<JobDetailsProperties> = ({ jobId, agentEndpointId = '', executionNumber = 0 }) => {
	const getJobDetailsOperation = useSelector(jobsSelectors.getJobDetails(jobId));
	const getJobExecutionDetailsOperation = useSelector(jobsSelectors.getJobExecutionDetails(jobId));
	const dispatch = useDispatch();
	const [value, setValue] = React.useState(0);

	useEffect(() => {
		dispatch(JobDetailsActions.loadJobDetails(jobId));
		if (agentEndpointId && executionNumber) {
			dispatch(JobDetailsActions.loadDeviceJobExecutionDetails(jobId, agentEndpointId, executionNumber));
		}
	}, []);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};


	const generateView = () => (
		<div data-cy={jobDetailsDataSelectors.container} className={styles.container} >
			<Box>
				<Tabs value={value} onChange={handleChange} aria-label="Tab for job and job executions">
					<Tab label="Details" {...getTabAttributes(0)} />
					<Tab label="Job executions" {...getTabAttributes(1)} />
				</Tabs>
				<TabPanel value={value} index={0}>
					<div className={styles.subContainer}>
						<h3>Job details</h3>
						{
							getJobDetailsOperation?.result && <Details object={(({ jobId, startedAt, status, updatedAt }) => ({ jobId, startedAt, status, updatedAt }))(getJobDetailsOperation.result)} />
						}
					</div>
				</TabPanel>
				<TabPanel value={value} index={1}>
					<div className={styles.subContainer}>
						<h3>Execution overview</h3>
						<Grid container spacing={2}>
							<Grid item xs={6} md={3}>
								<Box sx={{ textAlign: 'left' }}>
									<Box>
										Succeeded
									</Box>
									<Box sx={{ fontWeight: 'bold' }}>
										{getJobDetailsOperation?.result?.numberOfSucceeded}
									</Box>
								</Box>
							</Grid>
							<Grid item xs={6} md={3}>
								<Box sx={{ textAlign: 'left' }}>
									<Box>
										Failed
									</Box>
									<Box sx={{ fontWeight: 'bold' }}>
										{getJobDetailsOperation?.result?.numberOfFailed}
									</Box>
								</Box>
							</Grid>
							<Grid item xs={6} md={3}>
								<Box sx={{ textAlign: 'left' }}>
									<Box>
										Cancelled
									</Box>
									<Box sx={{ fontWeight: 'bold' }}>
										{getJobDetailsOperation?.result?.numberOfCanceled}
									</Box>
								</Box>
							</Grid>
							<Grid item xs={6} md={3}>
								<Box sx={{ textAlign: 'left' }}>
									<Box>
										Rejected
									</Box>
									<Box sx={{ fontWeight: 'bold' }}>
										{getJobDetailsOperation?.result?.numberOfRejected}
									</Box>
								</Box>
							</Grid>
							<Grid item xs={6} md={3}>
								<Box sx={{ textAlign: 'left' }}>
									<Box>
										Queued
									</Box>
									<Box sx={{ fontWeight: 'bold' }}>
										{getJobDetailsOperation?.result?.numberOfQueued}
									</Box>
								</Box>
							</Grid>
							<Grid item xs={6} md={3}>
								<Box sx={{ textAlign: 'left' }}>
									<Box>
										In progress
									</Box>
									<Box sx={{ fontWeight: 'bold' }}>
										{getJobDetailsOperation?.result?.numberOfInProgress}
									</Box>
								</Box>
							</Grid>
							<Grid item xs={6} md={3}>
								<Box sx={{ textAlign: 'left' }}>
									<Box>
										Removed
									</Box>
									<Box sx={{ fontWeight: 'bold' }}>
										{getJobDetailsOperation?.result?.numberOfRemoved}
									</Box>
								</Box>
							</Grid>
							<Grid item xs={6} md={3}>
								<Box sx={{ textAlign: 'left' }}>
									<Box>
										Timedout
									</Box>
									<Box sx={{ fontWeight: 'bold' }}>
										{getJobDetailsOperation?.result?.numberOfQueued}
									</Box>
								</Box>
							</Grid>
						</Grid>
					</div>
					{
						(agentEndpointId && executionNumber) ? <>
							<div className={styles.subContainer}>
								<h3>Job Execution Summary</h3>
								<Details object={getJobExecutionDetailsOperation?.result || {}} />
							</div></> : undefined
					}
				</TabPanel>
			</Box>
		</div>);

	return (
		<>
			{(getJobDetailsOperation && !getJobDetailsOperation.isLoading) ? generateView() : <div><CircularProgress data-cy='' color='primary' size={'2rem'} /></div>}
		</>
	);
};
