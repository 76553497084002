import {createSelector} from 'reselect';
import { Device, DeviceCommand, DeviceParameterValues, DeviceResponse, DeviceTriggerFirmwareUpdateResponse, DevicesResponse, ExchangeTokensResponse, Notification, SpeedTestStatusResponse } from '../../shared/types';

import {RootState} from '../reducers';
import {PaginatedResults, createOperationResultSelector, operationSelector} from '@indigo-cloud/common-react';

const getDevicesSelector = (state: RootState) => {
	return state?.devices.getDevicesOperation;
};

const findDeviceSelector = (state: RootState) => {
	return state?.devices.findDeviceOperation;
};

const getNotificationsSelector = (state: RootState) => {
	return state?.devices.getNotificationsOperation;
};

const getDevicesOperationSelector = createSelector(getDevicesSelector, (state) => operationSelector<DevicesResponse, Error>(state));
const getDevicesOperationResultsSelector = createOperationResultSelector(getDevicesOperationSelector);
const getDevicesDataSelector = createSelector(getDevicesOperationResultsSelector, (state) => state?.data);


const findDeviceOperationSelector = createSelector(findDeviceSelector, (state) => operationSelector<DeviceResponse, Error>(state));
const findDeviceOperationResultsSelector = createOperationResultSelector(findDeviceOperationSelector);
const findDeviceDataSelector = createSelector(findDeviceOperationResultsSelector, (state) => state?.data);


const triggerDeviceFirmwareUpdateSelector = (state: RootState) => {
	return state?.devices.triggerDeviceFirmwareUpdateOperation;
};

const triggerDeviceFirmwareUpdateOperationSelector = (agentEndpointId: string) => {
	return createSelector(triggerDeviceFirmwareUpdateSelector, (state) => {
		return operationSelector<DeviceTriggerFirmwareUpdateResponse, Error>(state[agentEndpointId]);
	})
};
const triggerDeviceFirmwareUpdateOperationResultsSelector = (agentEndpointId: string) => createOperationResultSelector(triggerDeviceFirmwareUpdateOperationSelector(agentEndpointId));

const getDeviceInfoSelector = (state: RootState) => {
	return state?.devices.getDeviceInfoOperation;
};

const getDeviceInfoOperationSelector = (agentEndpointId: string) => {
	return createSelector(getDeviceInfoSelector, (state) => {
		return operationSelector<Device, Error>(state[agentEndpointId]);
	})
};
const getDeviceInfoOperationResultsSelector = (agentEndpointId: string) => createOperationResultSelector(getDeviceInfoOperationSelector(agentEndpointId));

const getDeviceParameterValuesSelector = (state: RootState) => {
	return state?.devices.getDeviceParameterValuesOperation;
};

const getDeviceParameterValuesOperationSelector = (agentEndpointId: string) => {
	return createSelector(getDeviceParameterValuesSelector, (state) => {
		return operationSelector<DeviceParameterValues, Error>(state[agentEndpointId]);
	})
};
const getDeviceParameterValuesOperationResultsSelector = (agentEndpointId: string) => createOperationResultSelector(getDeviceParameterValuesOperationSelector(agentEndpointId));


const setDeviceInfoSelector = (state: RootState) => {
	return state?.devices.getDeviceInfoOperation;
};

const setDeviceInfoOperationSelector = (agentEndpointId: string) => {
	return createSelector(setDeviceInfoSelector, (state) => {
		return operationSelector<Device, Error>(state[agentEndpointId]);
	})
};
const setDeviceInfoOperationResultsSelector = (agentEndpointId: string) => createOperationResultSelector(setDeviceParameterValuesOperationSelector(agentEndpointId));

const setDeviceParameterValuesSelector = (state: RootState) => {
	return state?.devices.setDeviceParameterValuesOperation;
};

const setDeviceParameterValuesOperationSelector = (agentEndpointId: string) => {
	return createSelector(setDeviceParameterValuesSelector, (state) => {
		return operationSelector<DeviceParameterValues, Error>(state[agentEndpointId]);
	})
};


const addDeviceParameterValuesSelector = (state: RootState) => {
	return state?.devices.addDeviceParameterValuesOperation;
};

const addDeviceParameterValuesOperationSelector = (agentEndpointId: string) => {
	return createSelector(addDeviceParameterValuesSelector, (state) => {
		return operationSelector<DeviceParameterValues, Error>(state[agentEndpointId]);
	})
};

const addDeviceParameterValuesOperationResultsSelector = (agentEndpointId: string) => createOperationResultSelector(deleteDeviceParameterValuesOperationSelector(agentEndpointId));

const deleteDeviceParameterValuesSelector = (state: RootState) => {
	return state?.devices.deleteDeviceParameterValuesOperation;
};

const deleteDeviceParameterValuesOperationSelector = (agentEndpointId: string) => {
	return createSelector(deleteDeviceParameterValuesSelector, (state) => {
		return operationSelector<DeviceParameterValues, Error>(state[agentEndpointId]);
	})
};

const deleteDeviceParameterValuesOperationResultsSelector = (agentEndpointId: string) => createOperationResultSelector(deleteDeviceParameterValuesOperationSelector(agentEndpointId));

const setDeviceParameterValuesOperationResultsSelector = (agentEndpointId: string) => createOperationResultSelector(setDeviceParameterValuesOperationSelector(agentEndpointId));

const executeDeviceCommandSelector = (state: RootState) => {
	return state?.devices.executeDeviceCommandOperation;
};

const executeDeviceCommandOperationSelector = (agentEndpointId: string) => {
	return createSelector(executeDeviceCommandSelector, (state) => {
		return operationSelector<DeviceCommand[], Error>(state[agentEndpointId]);
	})
};
const executeDeviceCommandOperationResultsSelector = (agentEndpointId: string) => createOperationResultSelector(executeDeviceCommandOperationSelector(agentEndpointId));


const initSpeedTestSelector = (state: RootState) => {
	return state?.devices.initSpeedTestOperation;
};

const initSpeedTestOperationSelector = (agentEndpointId: string) => {
	return createSelector(initSpeedTestSelector, (state) => {
		return operationSelector<ExchangeTokensResponse, Error>(state[agentEndpointId]);
	})
};
const initSpeedTestOperationResultsSelector = (agentEndpointId: string) => createOperationResultSelector(initSpeedTestOperationSelector(agentEndpointId));

const triggerSpeedTestSelector = (state: RootState) => {
	return state?.devices.triggerSpeedTestOperation;
};

const triggerSpeedTestOperationSelector = (agentEndpointId: string) => {
	return createSelector(triggerSpeedTestSelector, (state) => {
		return operationSelector<SpeedTestStatusResponse, Error>(state[agentEndpointId]);
	})
};
const triggerSpeedTestOperationResultsSelector = (agentEndpointId: string) => createOperationResultSelector(triggerSpeedTestOperationSelector(agentEndpointId));

const getNotificationsOperationSelector = (agentEndpointId: string) => {
	return createSelector(getNotificationsSelector, (state) => {
		return operationSelector<{[pageNumberAndFilters: string]: PaginatedResults<Notification>}, Error>(state[agentEndpointId]);
	})
};

const getNotificationsOperationResultsSelector = (agentEndpointId: string) => createOperationResultSelector(getNotificationsOperationSelector(agentEndpointId));
const getNotificationsOperationResultsPageSelector = (pageNo: number, agentEndpointId: string, filters?: Record<string, unknown>) => {
	return createSelector(getNotificationsOperationResultsSelector(agentEndpointId), (state) => state?.[`${pageNo}_${JSON.stringify(filters)}`]);
}

export const devicesSelectors = {

	addDeviceParameterValuesOperation: addDeviceParameterValuesOperationSelector,
	addDeviceParameterValuesOperationResults: addDeviceParameterValuesOperationResultsSelector,

	deleteDeviceParameterValuesOperation: deleteDeviceParameterValuesOperationSelector,
	deleteDeviceParameterValuesOperationResults: deleteDeviceParameterValuesOperationResultsSelector,

	executeDeviceCommandOperation: executeDeviceCommandOperationSelector,
	executeDeviceCommandOperationResults: executeDeviceCommandOperationResultsSelector,

	findDeviceData: findDeviceDataSelector,
	findDeviceOperation: findDeviceOperationSelector,
	findDeviceOperationResults: findDeviceOperationResultsSelector,

	getDeviceInfoOperation: getDeviceInfoOperationSelector,
	getDeviceInfoOperationResults: getDeviceInfoOperationResultsSelector,


	getDeviceParameterValuesOperation: getDeviceParameterValuesOperationSelector,
	getDeviceParameterValuesOperationResults: getDeviceParameterValuesOperationResultsSelector,
	getDevicesData: getDevicesDataSelector,


	getDevicesOperation: getDevicesOperationSelector,
	getDevicesOperationResults: getDevicesOperationResultsSelector,

	getNotificationsOperation: getNotificationsOperationSelector,
	getNotificationsOperationResults: getNotificationsOperationResultsSelector,
	getNotificationsOperationResultsPage: getNotificationsOperationResultsPageSelector,

	initSpeedTestOperation: initSpeedTestOperationSelector,
	initSpeedTestOperationResults: initSpeedTestOperationResultsSelector,

	setDeviceInfoOperation: setDeviceInfoOperationSelector,
	setDeviceInfoOperationResults: setDeviceInfoOperationResultsSelector,


	setDeviceParameterValuesOperation: setDeviceParameterValuesOperationSelector,
	setDeviceParameterValuesOperationResults: setDeviceParameterValuesOperationResultsSelector,

	triggerDeviceFirmwareUpdateOperation: triggerDeviceFirmwareUpdateOperationSelector,
	triggerDeviceFirmwareUpdateOperationResults: triggerDeviceFirmwareUpdateOperationResultsSelector,
	triggerSpeedTestOperation: triggerSpeedTestOperationSelector,
	triggerSpeedTestOperationResults: triggerSpeedTestOperationResultsSelector
};



