import { ActionType, PayloadAction } from 'typesafe-actions';
import { LocationChangeAction } from 'connected-react-router';
import { authActions } from './Auth';
import { releaseActions } from './Releases';
import { menuActions } from './Menu';
import { rulesActions } from './Rules';
export const rootActions = {
	...authActions,
	...releaseActions,
	...rulesActions,
	...menuActions
};

export const rootActionsAll = {
	auth: authActions,
	menu: menuActions,
	releases: releaseActions,
	rules: rulesActions
};

export type FilterRootActionsByPayloadActions<Action extends rootActions> = Action extends PayloadAction<any, any>
	? Action
	: never;

export type RootPayloadActions = FilterRootActionsByPayloadActions<rootActions>;

export type rootActions = ActionType<typeof rootActions> | LocationChangeAction;
