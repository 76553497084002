import {createSelector} from 'reselect';
import { Firmware, Report, ReportsResponse } from '../../shared/types';

import {RootState} from '../reducers';
import { PaginatedResults, createOperationResultSelector, operationSelector } from '@indigo-cloud/common-react';


/**
 * CREATE_REPORT
 */
const createReportSelector = (state: RootState) => {
	return state?.report?.createReportOperation;
};


const createReportOperationSelector = createSelector(createReportSelector, (state) => operationSelector(state));
const createReportOperationResultSelector = createOperationResultSelector(createReportOperationSelector);

/**
 * DELETE_REPORT
 */
const deleteReportSelector = (state: RootState) => {
	return state?.report.deleteReportOperation;
};

const deleteReportOperationSelector = createSelector(deleteReportSelector, (state) => operationSelector(state));
const deleteReportOperationResultSelector = createOperationResultSelector(deleteReportOperationSelector);


/**
 * LIST_REPORT
 */
const listReportsSelector = (state: RootState) => {
	return state?.report.listReportsOperation;
};
const listReportsOperationSelector = createSelector(listReportsSelector, (state) => operationSelector<ReportsResponse, Error>(state));
const listReportsOperationResultsSelector = createOperationResultSelector(listReportsOperationSelector);
const listReportsOperationDataSelector = createSelector(listReportsOperationResultsSelector, (state) => state?.data);

/**
 * GET_REPORT_INFO
 */
const getReportInfoSelector = (state: RootState) => {
	return state?.report.loadReportInfoOperation;
};

const getReportInfoOperationSelector = (id: string) => {
	return createSelector(getReportInfoSelector, (state) => {
		return operationSelector<Report, Error>(state[id]);
	})
};
const getReportInfoOperationResultsSelector = (id: string) => createOperationResultSelector(getReportInfoOperationSelector(id));

/**
 * UPDATE_REPORT
 */
const updateReportSelector = (state: RootState) => {
	return state?.report?.updateReportOperation;
};

const updateReportOperationSelector = createSelector(updateReportSelector, (state) => operationSelector(state));
const updateReportOperationResultSelector = createOperationResultSelector(updateReportOperationSelector);


const getReportByIdentifiersSelector = (reportId: string | undefined) => createSelector(listReportsOperationDataSelector, (state) => {
	return state?.filter(({id}) => id === reportId)?.[0]
});

export const reportSelectors = {
	createReportOperation: createReportOperationSelector,
	createReportOperationResult: createReportOperationResultSelector,
	deleteReportOperation: deleteReportOperationSelector,
	deleteReportOperationResult: deleteReportOperationResultSelector,
	getReportByIdentifiers: getReportByIdentifiersSelector,



	getReportInfoOperation: getReportInfoOperationSelector,
	getReportInfoOperationResults: getReportInfoOperationResultsSelector,

	listReportsOperation: listReportsOperationSelector,
	listReportsOperationData: listReportsOperationDataSelector,

	updateReportOperation: updateReportOperationSelector,
	updateReportOperationResult: updateReportOperationResultSelector

};
