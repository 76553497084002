import { DeviceGroup } from '../types';
import { History } from 'history';
import { appRoutes } from '../../components';

export const getDeviceGroupIdentifier = ({ name }: DeviceGroup) => name;

export const getDeviceGroupIdentifierUrl = (device: DeviceGroup) => {
	const identifier = getDeviceGroupIdentifier(device);
	return appRoutes.DeviceGroupInfo.replace(':deviceGroup', encodeURIComponent(identifier));
};

export const navigateToDeviceGroupIdentifier = (device: DeviceGroup, history: History<unknown>) => {
	const identifierUrl = getDeviceGroupIdentifierUrl(device);
	history.push(identifierUrl);
}
