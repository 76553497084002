import MaterialTable from '@material-table/core';
import { useDispatch, useSelector } from 'react-redux';
import { deviceGroupsSelectors } from '../../../store/selectors';
import { green, red } from '@mui/material/colors';
import React, { useEffect, useRef, useState } from 'react';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import styles from './DeviceGroupsTable.component.module.scss';
import { deviceGroupsActions, devicesActions } from '../../../store/actions';
import { DeviceGroup } from '../../types';
import Tooltip from '@mui/material/Tooltip';
import Chip, { ChipTypeMap } from '@mui/material/Chip';
import { AppRouteNames, appRoutes } from '../../../App.router';
import { Link, useHistory } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import { CSVDownload, CSVLink } from 'react-csv';
import DownloadIcon from '@mui/icons-material/Download';

import { DeviceGroupInfo } from '../DeviceGroupInfo';

import { theme } from '../../../App.theme';
import { InputAdornment, TextField } from '@mui/material';
import { getDeviceGroupIdentifierUrl, navigateToDeviceGroupIdentifier } from '../../utils';

const columnStyle: React.CSSProperties = { fontFamily: theme.typography.fontFamily };

export const deviceGroupsTableDataSelectors = {
	container: 'DevicesTable-container'
};

export const DeviceGroupsTable: React.FC = ({}) => {
	const operationGetDevices = useSelector(deviceGroupsSelectors.getDeviceGroupsOperation);




	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(deviceGroupsActions.loadDeviceGroups());
	}, []);

	const history = useHistory();

	const data = operationGetDevices?.result?.data || [];

	const reference = useRef<any>(null);

	const [filteredData, setFilteredData] = useState([]);

	return (
		<div data-cy={deviceGroupsTableDataSelectors.container} id={styles.container} >


			<CSVLink
				// Can't use 'ref?.current?.state?.data' directly, seems to cause empty data
				data={filteredData}
				asyncOnClick={true}
				style={{ position: 'absolute', right: 6, top: 6 }}
				filename="Device Groups list"
				onClick={(event, done) => {
					setFilteredData(reference?.current?.state?.data?.map?.(({
						tableData,
						...rest
					}: any) => {
						return rest;
					}) || []);

					done();
				}}
			>

				<IconButton color="primary" aria-label="Export as CSV" component="label">
					<Tooltip title="Export as CSV">
						<DownloadIcon />
					</Tooltip>
				</IconButton>
			</CSVLink>



			<MaterialTable<DeviceGroup>
				title="Device Groups"
				tableRef={reference}
				columns={[
					{ align: 'left', cellStyle: columnStyle, field: 'id', headerStyle: columnStyle, title: 'ID' },
					{ align: 'left', cellStyle: columnStyle, field: 'name', headerStyle: columnStyle, title: 'Name' },
					{
						align: 'left',
						cellStyle: columnStyle,
						headerStyle: columnStyle,

						render: (deviceGroup) => {
							return (
								<div className={styles.containerRowActions}>
									<Tooltip title={'Open in a new tab'}>
										<Link to={getDeviceGroupIdentifierUrl(deviceGroup)} target="_blank" onClick={(error) => { error.stopPropagation() }}>
											<IconButton size="large">
												<OpenInBrowserIcon />
											</IconButton >
										</Link>
									</Tooltip>
								</div>
							);

						},
						title: 'Actions',
						width: 150
					}
				]}
				components={{
					// 2022-02-08 [DEF]:  https://github.com/material-table-core/website/blob/dc91f769de59d14adc4a921c9a15248e677cb38c/demos/filter/custom-filter-row.mdx#live-demo
					FilterRow: (rowProperties) => {

						const { actionsColumnIndex, columns, onFilterChanged, selection, hasDetailPanel } = rowProperties;

						return (
							<tr style={{ 'borderBottom': '1px solid rgba(224, 224, 224, 1)' }} key={actionsColumnIndex}>
								{selection && <td></td>}
								{hasDetailPanel && <td></td>}
								{columns.map((col: any) => {


									if (col.field) {
										return (
											<th style={{ 'padding': '24px 24px 24px 8px', 'textAlign': 'left'}} key={col.field}>
												<TextField
													variant="standard"
													InputProps={{
														startAdornment: (
															<InputAdornment position="start">
																<FilterListIcon />
															</InputAdornment>
														)
													}}
													id={col.field}
													onChange={event => onFilterChanged(col.tableData.id, event.target.value)}
												/>
											</th>
										);
									}

								})}
							</tr>
						);
					}
				}}
				options={{
					draggable: false,
					emptyRowsWhenPaging: false,
					filterCellStyle: { textAlign: 'center' },
					filtering: true,
					pageSize: 20,
					pageSizeOptions: [20, 50, 100, 500, 1000],
					searchFieldVariant: 'standard'
				}}
				data={data}
				onRowClick={(_, deviceGroup) => {
					navigateToDeviceGroupIdentifier(deviceGroup!, history);
				}}
				detailPanel={({rowData}) => {
					const { id, name } = rowData;
					console.log('[id]', {id, rowData})
					return (
						<DeviceGroupInfo name={name} />
					)
			 }}
			/>
		</div>);
};
