import {Auth} from '@aws-amplify/auth';
import {Dispatch} from 'react';
import {call, put} from '@redux-saga/core/effects';

export const signout = async () => {
	try {
		await Auth.signOut();
		localStorage.removeItem('federatedCredentials');
		window.location.reload();
	} catch (error) {
		console.error('An error occured while logging out the user', error);
	}
}

export const signoutComponent = async (dispatch: Dispatch<typeof authClearAction>, authClearAction: any) => {
	dispatch(authClearAction);
	await signout();
}

export function* signoutSaga(authClearAction: any) {
	yield put(authClearAction);
	yield call(signout);
}

export function* processUnauthenticatedResponse(authClearAction: any, error?: {response: {status: number;}}) {
	if (error?.response?.status === 401) {
		yield call(signoutSaga, authClearAction);
	}
}
