import {call, delay, put, select, takeEvery} from '@redux-saga/core/effects';
import { authActions, deviceGroupsActions} from '../actions';
import { ADD_DYNAMIC_DEVICE_GROUP, DELETE_DEVICE_GROUP, LOAD_DEVICE_GROUPS,
	LOAD_DEVICE_GROUPS_INFO,
	LOAD_DEVICE_GROUP_DEVICES,
	LOAD_DEVICE_GROUP_JOBS,
	TRIGGER_DEVICE_GROUP_ADD_DEVICES,
	TRIGGER_DEVICE_GROUP_FIRMWARE_UPDATE,
	TRIGGER_DEVICE_GROUP_REMOVE_DEVICES
} from '../constants';
import { DeviceGroup, awsExports } from '../../shared';
import { processUnauthenticatedResponse, request } from '@indigo-cloud/common-react';
import { deviceGroupsSelectors } from '../selectors';
import { AxiosError  } from 'axios';
import { checkTriggerFirmwareUpdateAuthorisation } from './Device.sagas';
import moment from 'moment';
import { push } from 'connected-react-router';
import { appRoutes } from '../../components';

const basePath = '/v1/deviceGroups';

function* loadDeviceGroups() {
	try {
		const response = yield call(
			request,
			{
				apiName: 'usp',
				awsExports,
				options: {
					queryStringParameters: {}
				},
				path: basePath
			}
		);
		yield put(deviceGroupsActions.loadDeviceGroupsSuccess(response));
	} catch (error) {
		console.error('An error occurred while loading the device groups.', error);
		yield put(deviceGroupsActions.loadDeviceGroupsError(error));
	}
}

function* loadDeviceGroupInfo(action: ReturnType<typeof deviceGroupsActions.loadDeviceGroupInfo>) {
	const { payload: { name } } = action;
	try {
		const response = yield call(
			request,
			{
				apiName: 'usp',
				awsExports,
				options: {
					queryStringParameters: {}
				},
				path: `${basePath}/${name}`
			}
		);
		yield put(deviceGroupsActions.loadDeviceGroupInfoSuccess(name, response));
	} catch (error) {
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error(`An error occurred while loading the device info with id '${name}'`, error);
		yield put(deviceGroupsActions.loadDeviceGroupInfoError(name, error));
	}
}

function* loadDeviceGroupJobs(action: ReturnType<typeof deviceGroupsActions['loadDeviceGroupJobs']>) {
	const {
		payload: {filters, name, limit = 10, cursor, pageIndex = 0}
	} = action;
	try {
		console.log('filters', filters)

		const keysFilters = Object.keys(filters || {});
		let search = '';
		if (keysFilters?.length) {
			search = keysFilters.reduce((previous, keyFilter) => {
				const filter = filters![keyFilter] as string;
				previous += `&${encodeURIComponent(`search[${keyFilter}]`)}=${encodeURIComponent(filter)}`;

				return previous;
			}, '');

			console.log('search', search)
		}

		const requestUrl = `${basePath}/${name}/jobs?limit=${limit}${cursor?.length ? `&cursor=${cursor}` : ''}${Object.keys(filters || '').length > 0 ? search : ''}`

		const response = yield call(
			request,
			{
				apiName: 'usp',
				awsExports,
				path: requestUrl
			}
		);
		const isoFormat = 'YYYY-MM-DDTHH:mm:ss';
		if (Array.isArray(response.data)) {
			response.data.forEach((job: any) => {
				const startedAtDate = moment(job.startedAt, isoFormat).toDate();
				const updatedAtDate = moment(job.updatedAt, isoFormat).toDate();
				job.startedAt = moment(startedAtDate).format('DD/MM/YYYY HH:mm:ssA');
				job.updatedAt = moment(updatedAtDate).format('DD/MM/YYYY HH:mm:ssA');
			});
		}
		yield put(deviceGroupsActions.loadDeviceGroupJobsSuccess(name, response, pageIndex, filters));
	} catch (error) {
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		yield put(deviceGroupsActions.loadDeviceGroupJobsError(error));
	}
}


function* loadDeviceGroupDevices(action: ReturnType<typeof deviceGroupsActions.loadDeviceGroupDevices>) {
	const { payload: {filters, limit = 250, cursor, pageIndex = 0, name} } = action;
	try {
		const requestUrl = `${basePath}/${encodeURIComponent(name)}/devices?limit=${limit}${cursor?.length ? `&cursor=${cursor}` : ''}`
		const response = yield call(
			request,
			{
				apiName: 'usp',
				awsExports,
				options: {
					queryStringParameters: {}
				},
				path: requestUrl
			}
		);
	
		yield put(deviceGroupsActions.loadDeviceGroupDevicesSuccess({
			data: response.data,
			pagination: response?.pagination
		}, name, pageIndex, filters));
	} catch (error) {
		console.error('An error occurred while loading the devices for a device group.', error);
		yield put(deviceGroupsActions.loadDeviceGroupDevicesError(error, name));
	}
}

function* triggerDeviceGroupFirmwareUpdate(action: ReturnType<typeof deviceGroupsActions.triggerDeviceGroupFirmwareUpdate>) {

	const { payload: { deviceGroup, firmwareName, formikPromise, shouldForceDowngrade, activationWindows, abortConfig, executionsRolloutConfig, schedulingConfig } } = action;

	try {

		checkTriggerFirmwareUpdateAuthorisation();

		const response = yield call(
			request,
			{
				apiName: 'rom',
				awsExports,
				method: 'post',
				options: {
					body: {
						abortConfig,
						activationWindow: activationWindows,
						executionsRolloutConfig,
						firmwareName,
						forceDowngrade: shouldForceDowngrade,
						
						
						
						schedulingConfig
					}
				},
				path: `${basePath}/${encodeURIComponent(deviceGroup)}/triggerFirmwareUpdate`
			}
		);

		

		yield put(deviceGroupsActions.triggerDeviceGroupFirmwareUpdateSuccess(response, deviceGroup));

		formikPromise.resolve(response);

	} catch (error) {
		const errorAxios = (error as AxiosError);
		let errorMessage = '';
		errorMessage = errorAxios.isAxiosError ? (typeof errorAxios?.response?.data === 'string' ? errorAxios?.response?.data :  errorAxios?.response?.data.message) : error?.message;

		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error(`An error occurred while triggering the firmware update for device group '${deviceGroup}'`, errorMessage);
		console.log('[error]', errorMessage);
		yield put(deviceGroupsActions.triggerDeviceGroupFirmwareUpdateError({
			message: errorMessage
		} as any, deviceGroup));
		formikPromise.reject(error);
	}
}


function* deleteDeviceGroupSaga(action: ReturnType<typeof deviceGroupsActions['deleteDeviceGroup']>) {
	const {
		payload: {deviceGroupId}
	} = action;
	try {

		const response = yield call(
			request,
			{
				apiName: 'usp',
				awsExports,
				method: 'del',
				path: `${basePath}/${encodeURIComponent(deviceGroupId)}`
			}
		);

		yield put(deviceGroupsActions.deleteDeviceGroupSuccess(deviceGroupId, response));
		yield put(deviceGroupsActions.loadDeviceGroups());
		yield put(push(appRoutes.DeviceGroup));
	} catch (error) {
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error('An error occurred while deleting the deviceGroup', error, deviceGroupId);
		yield put(deviceGroupsActions.deleteDeviceGroup(error));
	}
}

function* triggerDeviceGroupAddDevices(action: ReturnType<typeof deviceGroupsActions.triggerDeviceGroupAddDevices>) {
	const { payload: { deviceGroup, devices, formikPromise } } = action;

	try {

		
		const batchSize = 250;

		const numberBatches = Math.ceil(devices.thingNames.length / batchSize);

		for (let index = 0; index < numberBatches; index++) {
			const start = index * batchSize;
			const end = (index + 1) * batchSize;
			
			const batch = devices.thingNames.slice(start, end);

			yield call(
				request,
				{
					apiName: 'usp',
					awsExports,
					method: 'post',
					options: {
						body: {
							thingNames: batch
						}
					},
					path: `${basePath}/${encodeURIComponent(deviceGroup)}/devices`
				}
			);
		}

		yield put(deviceGroupsActions.triggerDeviceGroupAddDevicesSuccess({}, deviceGroup));

		formikPromise.resolve({});
	} catch (error) {
		const errorAxios = (error as AxiosError);
		let errorMessage = '';
		errorMessage = errorAxios.isAxiosError ? errorAxios?.response?.data : error.message;
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error(`An error occurred while triggering to add devices to the device group '${deviceGroup}'`, errorMessage);
		console.log('[error]', errorMessage);
		yield put(deviceGroupsActions.triggerDeviceGroupAddDevicesError({
			message: errorMessage
		} as any, deviceGroup));
		formikPromise.reject(error);
	}
}

function* triggerDeviceGroupRemoveDevices(action: ReturnType<typeof deviceGroupsActions.triggerDeviceGroupRemoveDevices>) {
	const { payload: { deviceGroup, devices, formikPromise } } = action;

	try {

		const response = yield call(
			request,
			{
				apiName: 'usp',
				awsExports,
				method: 'del',
				options: {
					body: devices
				},
				path: `${basePath}/${encodeURIComponent(deviceGroup)}/devices`
			}
		);

		yield put(deviceGroupsActions.triggerDeviceGroupRemoveDevicesSuccess(response, deviceGroup));

		formikPromise.resolve(response);
	} catch (error) {
		const errorAxios = (error as AxiosError);
		let errorMessage = '';
		errorMessage = errorAxios.isAxiosError ? errorAxios?.response?.data : error.message;
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error(`An error occurred while triggering to remove devices from the device group '${deviceGroup}'`, errorMessage);
		console.log('[error]', errorMessage);
		yield put(deviceGroupsActions.triggerDeviceGroupRemoveDevicesError({
			message: errorMessage
		} as any, deviceGroup));
		formikPromise.reject(error);
	}
}

function* addDynamicDeviceGroup(action: ReturnType<typeof deviceGroupsActions.addDynnamicGroup>) {

	const { payload: { formikPromise, model } } = action;
	try {

		const response = yield call(
			request,
			{
				apiName: 'usp',
				awsExports,
				method: 'post',
				options: {
					body: model
				},
				path: `${basePath}`
			}
		);

		yield put(deviceGroupsActions.addDynnamicGroupSuccess(response.data));
		formikPromise.resolve(response);
	} catch (error) {
		const errorAxios = (error as AxiosError);
		let errorMessage = '';
		errorMessage = errorAxios.isAxiosError ? (typeof errorAxios?.response?.data === 'string' ? errorAxios?.response?.data :  errorAxios?.response?.data.message) : error?.message;
		
		if(errorAxios?.response?.status === 400 && errorMessage) {
			const errorMessageArray = errorMessage.split('|');
			errorMessage = errorMessageArray.length > 1 ? errorMessageArray.slice(1).join(' ') : errorMessage;
		}

		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error('An error occurred while creating a dynamic device group.', error);
		yield put(deviceGroupsActions.addDynnamicGroupError({
			message: errorMessage
		} as any));
		formikPromise.reject(error);
	}
}

export const deviceGroupsSagas = () => {

	function* watcher() {
		yield takeEvery(LOAD_DEVICE_GROUPS, loadDeviceGroups);
		yield takeEvery(LOAD_DEVICE_GROUPS_INFO, loadDeviceGroupInfo);
		yield takeEvery(TRIGGER_DEVICE_GROUP_FIRMWARE_UPDATE, triggerDeviceGroupFirmwareUpdate);
		yield takeEvery(TRIGGER_DEVICE_GROUP_ADD_DEVICES, triggerDeviceGroupAddDevices);
		yield takeEvery(TRIGGER_DEVICE_GROUP_REMOVE_DEVICES, triggerDeviceGroupRemoveDevices);
		yield takeEvery(LOAD_DEVICE_GROUP_JOBS, loadDeviceGroupJobs);
		yield takeEvery(LOAD_DEVICE_GROUP_DEVICES, loadDeviceGroupDevices);
		yield takeEvery(ADD_DYNAMIC_DEVICE_GROUP, addDynamicDeviceGroup);
		yield takeEvery(DELETE_DEVICE_GROUP, deleteDeviceGroupSaga);
		
	}

	return {
		loadDeviceGroupInfo,
		loadDeviceGroups,
		watcher
	};
};
