import {call ,put, takeEvery} from '@redux-saga/core/effects';
import * as AWS from 'aws-sdk';
import {authActions, usersActions} from '../actions';
import { DISABLE_USER, LIST_USERS, UPDATE_USER } from '../constants';
import { push } from 'connected-react-router';
import jwtDecode from 'jwt-decode';
import { appRoutes } from '../../App.router';
import { cognito, processUnauthenticatedResponse, request } from '@indigo-cloud/common-react';
import { User, awsExports } from '../../shared';
import { delay } from 'bluebird';
import { Auth } from '@aws-amplify/auth';
import { Hub } from 'aws-amplify';
import moment from 'moment';

const baseUsersPath = '/v1/admin/user';

function* loadUsers() {
	try {

		const assumeXRole = async () => {
			try {

				const user = await Auth.currentAuthenticatedUser();

				const accessToken = user.token;
				const accessTokenDecoded = jwtDecode(accessToken) as any;

				const adminGroup = accessTokenDecoded?.['cognito:roles']?.[0];

				const {
					accessKeyId,
					secretAccessKey,
					sessionToken
				} = await Auth.currentCredentials();

				/* eslint-disable-next-line import/namespace */
				const sts = new AWS.STS({
					credentials: new AWS.Credentials(accessKeyId, secretAccessKey, sessionToken)
				});

				const callerIdentity = await sts.getCallerIdentity({}).promise();

				// Don't assume the role twice. Only assume if we're on cognito identity pool credential
				if (!callerIdentity.Arn!.includes('admin')) {
					const result = await sts.assumeRole({
						// RoleArn: 'arn:aws:iam::079439661102:role/crossacc-test',
						RoleArn: adminGroup,
						RoleSessionName: Date.now().toString()
					}).promise();

					const credentials = new AWS.Credentials(result.Credentials!.AccessKeyId, result.Credentials!.SecretAccessKey, result.Credentials!.SessionToken)
					// eslint-disable-next-line import/namespace
					cognito.client = new AWS.CognitoIdentityServiceProvider({ credentials, region: 'eu-west-1' });

					return result;
				}
			}
			catch (error) {
				console.error('An error occurred while assuming the role', error);
			}

			return;
		}

		const listUsers = async () => {

			const queryUsers = async (paginationToken?: string, results?: AWS.CognitoIdentityServiceProvider.UsersListType): Promise<any> => {
				let concatonatedUsers = results || [];
				const {
					PaginationToken,
					Users
				} = await cognito.client!.listUsers({
					PaginationToken: paginationToken,
					UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!

				}).promise();

				concatonatedUsers = [...concatonatedUsers, ...Users || []] as AWS.CognitoIdentityServiceProvider.UsersListType;

				if (PaginationToken) {
					return queryUsers(PaginationToken, concatonatedUsers);
				}

				return concatonatedUsers;
			}

			const allUsers = (await queryUsers()) as AWS.CognitoIdentityServiceProvider.UsersListType;

			// @ts-ignore
			const usersWithGroups = await Promise.all(allUsers?.map(async (user) => {

				const result = await cognito.client.adminListGroupsForUser({

					UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID as string,
					Username: user?.Username as string

				}).promise()

				const id = user?.Attributes?.filter(({ Name }) => Name === 'sub')?.[0];
				// const id = user?.Attributes?.filter(({ Name }) => Name === 'sub');

				return {
					// ...user,

					createdAt: user?.UserCreateDate,

					groups: result?.Groups?.map(({
						GroupName
					}) => GroupName)?.filter((group) => group?.indexOf('Crowd') === -1) || [],
					id: id?.Value,
					status: user?.Enabled ? 'enabled' : 'disabled',
					updatedAt: user?.UserLastModifiedDate,
					username: user?.Username
				}
			}))

			return usersWithGroups
		}

		yield call(assumeXRole);

		const response = yield call(
			listUsers
		);
		// Convert dates using moment before putting them in the store
		const responseConverted = response.map((user: User) => ({
			...user,
			createdAt: user.createdAt ? moment(user.createdAt).format('DD/MM/YYYY') + ' (' + moment(user.createdAt).fromNow() + ')': 'N/A',
			updatedAt: user.updatedAt ? moment(user.updatedAt).format('DD/MM/YYYY') + ' (' + moment(user.updatedAt).fromNow() + ')': 'N/A'
		}));

		yield put(usersActions.listUsersSuccess(responseConverted));
	} catch (error) {
		console.error('An error occurred while listing users', error);
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		yield put(usersActions.listUsersError(error));
	}
}

function* disableUser(action: ReturnType<typeof usersActions['disableUser']>) {
	const {
		payload: {model: user}
	} = action;
	try {

		const response = yield call(
			request,
			{
				awsExports,
				method: 'del',
				path: `${baseUsersPath}`
			}
		);

		yield put(usersActions.disableUserSuccess(response));
		yield put(usersActions.listUsers());
		yield put(push(appRoutes.UserManagement));

	} catch (error) {
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error('An error occurred while deleting the user', error, user);
		yield put(usersActions.disableUserError(error));
	}
}

function* updateUser(action: ReturnType<typeof usersActions['updateUser']>) {
	const {
		payload: {oldUser, newUser, formikPromise}
	} = action;
	try {

		console.log(`Started updating the User with target firmware '${oldUser}'.`);

		const groupsToRemove = oldUser.groups.filter(x => !newUser.groups.includes(x));
		const groupsToAdd = newUser.groups.filter(x => !oldUser.groups.includes(x));

		const updateGroups = async () => {
			const removedGroups = await Promise.all(groupsToRemove.map((groupToRemove) => {

				return cognito.client['adminRemoveUserFromGroup']({
					GroupName: groupToRemove,

					UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
					Username: newUser.username!
				}).promise();
			}));

			const addedGroups = await Promise.all(groupsToAdd.map((groupToAdd) => {
				return cognito.client['adminAddUserToGroup']({
					GroupName: groupToAdd,

					UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
					Username: newUser.username!
				}).promise();

			}));

			return {
				addedGroups,
				removedGroups
			};
		}

		const response = yield call(
			updateGroups
		);

		yield put(usersActions.updateUserSuccess(response));
		yield put(usersActions.listUsers());
		formikPromise.resolve(response);

		yield put(push(appRoutes.UserManagement));

		console.log(`Successfully completed updating the User with target firmware '${oldUser}'.`);
	} catch (error) {
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error(`An error occurred while updating the user with target firmware '${oldUser}'.`, error);
		yield put(usersActions.updateUserError(error));
		formikPromise.reject(error);
	}
}

export const usersSagas = () => {

	function* watcher() {
		yield takeEvery(LIST_USERS, loadUsers);
		yield takeEvery(DISABLE_USER, disableUser);
		yield takeEvery(UPDATE_USER, updateUser);
	}

	return {
		loadUsers,
		watcher
	};
};
