import {combineReducers} from 'redux';
import {StateType} from 'typesafe-actions';
import {LOCATION_CHANGE, RouterState} from 'connected-react-router';
import {DeepReadonlyObject} from '@indigo-cloud/common-react';

export type LocationState = DeepReadonlyObject<{
  history: RouterState[];
}>;
export const initialLocationState: LocationState = {
	history: []
};
const HISTORY_LENGTH = 3;
export const locationReducers = combineReducers<LocationState, any>({
	history: (state = initialLocationState.history, action) => {
		const { type } = action;

		switch (type) {
		case LOCATION_CHANGE: {
			const { payload } = action;
			if (
				state.length > 0 &&
          state[0].location.pathname === payload.location.pathname
			) {
				return state;
			}

			switch (payload.action) {
			case 'REPLACE':
				// if replacing current entry, don't "push" on new route, replace "top"
				return [payload, ...state.slice(1)];

			default: // PUSH
				return [payload, ...state].slice(0, HISTORY_LENGTH);
			}
		}

		default:
			return state;
		}
	}
});

// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-redeclare
export type locationReducers = StateType<typeof locationReducers>;
