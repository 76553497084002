
/**
 * LOAD_DEVICE_GROUPS
 */
export const LOAD_DEVICE_GROUPS = 'DEVICEGROUPS.LOAD_DEVICE_GROUPS';
export const LOAD_DEVICE_GROUPS_SUCCESS = 'DEVICEGROUPS.LOAD_DEVICE_GROUPS_SUCCESS';
export const LOAD_DEVICE_GROUPS_ERROR = 'DEVICEGROUPS.LOAD_DEVICE_GROUPS_ERROR';

export const LOAD_DEVICE_GROUPS_INFO = 'DEVICEGROUPS.LOAD_DEVICE_GROUPS_INFO';
export const LOAD_DEVICE_GROUP_INFO_ERROR = 'DEVICEGROUPS.LOAD_DEVICE_GROUP_INFO_ERROR';
export const LOAD_DEVICE_GROUP_INFO_SUCCESS = 'DEVICEGROUPS.LOAD_DEVICE_GROUP_INFO_SUCCESS';

export const DELETE_DEVICE_GROUP = 'DEVICEGROUPS.DELETE_DEVICE_GROUPS';
export const DELETE_DEVICE_GROUP_ERROR = 'DEVICEGROUPS.DELETE_DEVICE_GROUP_ERROR';
export const DELETE_DEVICE_GROUP_SUCCESS = 'DEVICEGROUPS.DELETE_DEVICE_GROUP_SUCCESS';

/**
 * TRIGGER_DEVICE_GROUP_FIRMWARE_UPDATE
 */
export const TRIGGER_DEVICE_GROUP_FIRMWARE_UPDATE = 'DEVICEGROUPS.TRIGGER_DEVICE_GROUP_FIRMWARE_UPDATE';
export const TRIGGER_DEVICE_GROUP_FIRMWARE_UPDATE_SUCCESS = 'DEVICEGROUPS.TRIGGER_DEVICE_GROUP_FIRMWARE_UPDATE_SUCCESS';
export const TRIGGER_DEVICE_GROUP_FIRMWARE_UPDATE_ERROR = 'DEVICEGROUPS.TRIGGER_DEVICE_GROUP_FIRMWARE_UPDATE_ERROR';

export const LOAD_DEVICE_GROUP_JOBS = 'DEVICEGROUPS.LOAD_DEVICE_GROUP_JOBS';
export const LOAD_DEVICE_GROUP_JOBS_SUCCESS = 'DEVICEGROUPS.LOAD_DEVICE_GROUP_JOBS_SUCCESS';
export const LOAD_DEVICE_GROUP_JOBS_ERROR = 'DEVICEGROUPS.LOAD_DEVICE_GROUP_JOBS_ERROR'
export const LOAD_DEVICE_GROUP_JOBS_CLEAR = 'DEVICEGROUPS.LOAD_DEVICE_GROUP_JOBS_CLEAR'

export const LOAD_DEVICE_GROUP_DEVICES = 'DEVICEGROUPS.LOAD_DEVICE_GROUP_DEVICES';
export const LOAD_DEVICE_GROUP_DEVICES_SUCCESS = 'DEVICEGROUPS.LOAD_DEVICE_GROUP_DEVICES_SUCCESS';
export const LOAD_DEVICE_GROUP_DEVICES_ERROR = 'DEVICEGROUPS.LOAD_DEVICE_GROUP_DEVICES_ERROR';
export const LOAD_DEVICE_GROUP_DEVICES_CLEAR = 'DEVICEGROUPS.LOAD_DEVICE_GROUP_DEVICES_CLEAR'

/**
 * TRIGGER_DEVICE_GROUP_ADD_DEVICES
 */
export const TRIGGER_DEVICE_GROUP_ADD_DEVICES= 'DEVICEGROUPS.TRIGGER_DEVICE_GROUP_ADD_DEVICES';
export const TRIGGER_DEVICE_GROUP_ADD_DEVICES_ERROR= 'DEVICEGROUPS.TRIGGER_DEVICE_GROUP_ADD_DEVICES_ERROR';
export const TRIGGER_DEVICE_GROUP_ADD_DEVICES_SUCCESS= 'DEVICEGROUPS.TRIGGER_DEVICE_GROUP_ADD_DEVICES_SUCCESS';

/**
 * TRIGGER_DEVICE_GROUP_REMOVE_DEVICES
 */
export const TRIGGER_DEVICE_GROUP_REMOVE_DEVICES= 'DEVICEGROUPS.TRIGGER_DEVICE_GROUP_REMOVE_DEVICES';
export const TRIGGER_DEVICE_GROUP_REMOVE_DEVICES_ERROR= 'DEVICEGROUPS.TRIGGER_DEVICE_GROUP_REMOVE_DEVICES_ERROR';
export const TRIGGER_DEVICE_GROUP_REMOVE_DEVICES_SUCCESS= 'DEVICEGROUPS.TRIGGER_DEVICE_GROUP_REMOVE_DEVICES_SUCCESS';

export const ADD_DYNAMIC_DEVICE_GROUP= 'DEVICEGROUPS.ADD_DYNAMIC_DEVICE_GROUP';
export const ADD_DYNAMIC_DEVICE_GROUP_ERROR= 'DEVICEGROUPS.ADD_DYNAMIC_DEVICE_GROUP_ERROR';
export const ADD_DYNAMIC_DEVICE_GROUP_SUCCESS= 'DEVICEGROUPS.ADD_DYNAMIC_DEVICE_GROUP_SUCCESS';
