export enum Role {
    Admin = 'Admin',
    Device ='Device',
	DeviceGroups = 'DeviceGroups',
	Firmware = 'Firmware',
	Readonly = 'Readonly',
	Reports = 'Reports',
	Suota = 'Suota',
	Standard = 'Standard'
}
