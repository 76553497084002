import MaterialTable from '@material-table/core';
import { PaginatedTable, PaginatedTableProperties, Role, isAuthorised, useAuthorisation } from '@indigo-cloud/common-react';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { InputAdornment, Paper, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import { ModalConfirmation } from '@indigo-cloud/common-react';
import { deviceGroupsActions } from '../../../store/actions'
import { theme } from '../../../App.theme';
import { deviceGroupsSelectors } from '../../../store/selectors';
import { Thing } from '../../types';
import styles from './ThingsTable.component.module.scss';
import Box from '@mui/system/Box';
import { navigateToDeviceIdentifierByThingName } from '../../utils';

interface ThingsTableProperties extends Pick<PaginatedTableProperties<Thing>, 'onRowSelectionChanged'> {
	name: string
	isLoading?: boolean;
	inherittedFilters?: Record<string, unknown>;
	customStyle?: Record<string, unknown>;
	groupType?: string
}
export const thingsTableDataSelectors = {
	actionDeleteIconButton: 'ThingsTable-actionDeleteIconButton',
	container: 'ThingsTable-container'
};

export const ThingsTable: React.FC<ThingsTableProperties> = ({ inherittedFilters, name, isLoading, customStyle = {}, groupType, ...tableProperties }) => {
	
	const [pageSize, setPageSize] = React.useState(10);
	const [pageIndex, setPageIndex] = React.useState(0);
	const [filters, setFilters] = React.useState(undefined);

	const deviceGroupDevicesPaged = useSelector(deviceGroupsSelectors.listDeviceGroupDevicesOperationPage(name, pageIndex, filters));
	const deviceGroupDevicesAll = useSelector(deviceGroupsSelectors.listDeviceGroupDevicesOperationAll(name, pageIndex, filters));
	
	const deviceGroupDevicesOperation = useSelector(deviceGroupsSelectors.listDeviceGroupDevicesOperation(name));

	

	const userAuthorisationGroups = useAuthorisation();

	const [deleteAction, setDeleteAction] = React.useState(false);
	const [thingToDelete, setThingToDelete] = React.useState<string[]>([]);

	const DeviceRemoveOperation = useSelector(deviceGroupsSelectors.triggerDeviceGroupDevicesRemoveOperation(name));
	const didRemoveOperationSucceed = DeviceRemoveOperation?.didSucceed === true;

	const reference = useRef<any>(null);
	const dispatch = useDispatch();

	const hasCursor = useMemo(() => {
		return !!deviceGroupDevicesPaged?.pagination?.cursor;
	}, [deviceGroupDevicesPaged?.pagination]);

	const history = useHistory();

	useEffect(() => {
		dispatch(deviceGroupsActions.loadDeviceGroupDevices({
			name
			
		}));
		return () => {
			dispatch(deviceGroupsActions.loadDeviceGroupDevicesClear(name));
		}
	}, []);

	useEffect(() => {
		if (didRemoveOperationSucceed === true) {
			dispatch(deviceGroupsActions.loadDeviceGroupDevices({
				name
			}));
			dispatch(deviceGroupsActions.loadDeviceGroupDevicesClear(name));
		}
	}, [didRemoveOperationSucceed]);

	const onPressAction = (action: 'delete', thing: string[]) => {
		switch (action) {
		case 'delete':
			setThingToDelete(thing);
			// confirmation modal gets opened once delete action is true
			setDeleteAction(true);
			break;
		}
	}

	const onPageChanged = (page: number) => {
		setPageIndex(page);
		const hasRequestedPageInState = !!deviceGroupDevicesOperation?.result?.[`${page.toString()}_${JSON.stringify(filters)}`];

		if (!hasRequestedPageInState) {
			dispatch(deviceGroupsActions.loadDeviceGroupDevices({name, cursor: deviceGroupDevicesPaged?.pagination.next, filters, pageIndex: page}));
		}
	};

	const deleteThingFromGroup = async () => {
		try {
			await new Promise((resolve, reject) => {
				if (thingToDelete.length > 0) {
					dispatch(deviceGroupsActions.triggerDeviceGroupRemoveDevices(name, { 'thingNames': thingToDelete }, { reject, resolve }));
				}
			});
		}
		catch (error) {
			console.log('An error occurred while deleting the thing from the group', error);
		}
		finally {
			console.log('dispatched action to delete the thing from the group');
		}
		//resetting once delete action completed, that closes the confirmation modal as well
		setDeleteAction(false);
	}

	const generateDeleteConfirmModal = () => (
		<ModalConfirmation
			action='delete'
			title='Confirm Deletion'
			isOpen={deleteAction}
			onClickOk={deleteThingFromGroup}
			onClickClose={() => setDeleteAction(false)}
		/>
	);

	const onRowClick = useCallback((row: Thing) => {
		navigateToDeviceIdentifierByThingName(row.thingName, history);
	}, [name, history]);

	const generateView = () => (
		<div data-cy={thingsTableDataSelectors.container} id={styles.container} >
			<CSVLink
				// Can't use 'ref?.current?.state?.data' directly, seems to cause empty data
				data={deviceGroupDevicesAll}
				asyncOnClick={true}
				filename={`Devices in ${name}`}
				style={{ position: 'absolute', right: 6, top: 6 }}
			>

				<IconButton color="primary" aria-label="Export as CSV" component="label">
					<Tooltip title="Export as CSV">
						<DownloadIcon />
					</Tooltip>
				</IconButton>
			</CSVLink>

			<PaginatedTable
				ref={reference}

				columns={[
					{
						Header: (deviceGroupDevicesPaged?.pagination?.total !== undefined ) ? `Thing Name (${deviceGroupDevicesPaged?.pagination?.total})` : '',
						accessor: 'thingName',
						disableFilters: true
						
					},
					...isAuthorised([Role.DeviceGroups, Role.Standard, Role.Admin], userAuthorisationGroups) ? [{
						Cell: ({row}: any) => groupType !== 'DYNAMIC' && (
							<Box display="flex" justifyContent="flex-end">
								<Tooltip title='Delete Thing'>
									<IconButton
										onClick={() => onPressAction('delete', [row?.values?.thingName])}
										data-cy={thingsTableDataSelectors.actionDeleteIconButton}
										size="large">
										<DeleteIcon />
									</IconButton>
								</Tooltip>
							</Box>
						),
						Header: ' ',
						accessor: 'paramValue' as any,
						disableFilters: true
					}] : []
				]}
				data={deviceGroupDevicesPaged?.data || []}
				onPageChanged={onPageChanged}
				/* eslint-disable-next-line @typescript-eslint/no-empty-function  */
				onFiltersChanged={() => {}}
				// Seems to be bug with pageIndex in react-table where it doesn't always update on nextPage(). So use redux pagine index instead.
				pageIndex={pageIndex}
				hasCursor={hasCursor}
				loading={!!deviceGroupDevicesOperation?.isLoading}
				theme={theme}
				customStyle={{bodyAlign: 'center', headerAlign: 'center', ...customStyle }}
				navigateToRowDetails={onRowClick}
				{...tableProperties}
			/>
			{deleteAction ? generateDeleteConfirmModal() : ''}
		</div>);

	return (
		<>
			{(isLoading || deviceGroupDevicesOperation?.isLoading) ? <div><CircularProgress data-cy='' color='primary' size={'2rem'} /></div> : generateView()}
		</>
	);
};